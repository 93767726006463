//React
import React from 'react';

//Components
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/AddCircle';
import Grid from '@material-ui/core/Grid';

//Other Reqs
const { fromJS } = require('immutable');

export default function AgregarMovimiento(props) {
    const [open, setOpen] = React.useState(false);
    const [fecha, setFecha] = React.useState("");
    const [concepto, setConcepto] = React.useState("");
    const [tipo, setTipo] = React.useState("abono");
    const [cantidad, setCantidad] = React.useState(0);

    function addMovement(event) {
        event.preventDefault()
        var h = props.historialPagos
        switch(tipo){
            case 'abono':
                var abonos = h.abonoTotal != "" ? parseInt(h.abonoTotal) : 0
                var total = parseInt(h.total)
                total -= parseInt(cantidad)
                abonos += parseInt(cantidad)
                if(h.abonos.length > 0){
                    if(h.abonos[0].cantidad == ''){
                        h.abonos[0] = {
                            cantidad : cantidad,
                            concepto : concepto,
                            fecha : fecha
                        }
                    }else{
                        h.abonos.push({
                            cantidad : cantidad,
                            concepto : concepto,
                            fecha : fecha
                        })
                    }
                }else{
                    h.abonos.push({
                        cantidad : cantidad,
                        concepto : concepto,
                        fecha : fecha
                    })
                }
                h.abonoTotal = "$" + abonos + ".00"
                h.total = "$" + total + ".00"
                break
            case 'cargo':
                var cargos = h.cargoTotal != "" ? parseInt(h.cargoTotal) : 0
                var total = parseInt(h.total)
                total += parseInt(cantidad)
                cargos += parseInt(cantidad)
                if(h.cargos.length > 0){
                    if(h.cargos[0].cantidad == ''){
                        h.cargos[0] = {
                            cantidad : cantidad,
                            concepto : concepto,
                            fecha : fecha
                        }
                    }else{
                        h.cargos.push({
                            cantidad : cantidad,
                            concepto : concepto,
                            fecha : fecha
                        })
                    }
                }else{
                    h.cargos.push({
                        cantidad : cantidad,
                        concepto : concepto,
                        fecha : fecha
                    })
                }
                h.cargoTotal = "$" + cargos + ".00"
                h.total = "$" + total + ".00"
                break
            default:
                alert("Movimiento Desconocido.")
        }
        var he = fromJS(h)
        props.setHistorialPagos(he.toJS())
        props.setSnackbar({open: true, variant: 'info', text: 'Se ha agregado el movimiento.'})
        setOpen(false);
    }

    function handleClose() {
        setOpen(false);
    }

    return (
        <div>
            <Tooltip title="Agregar Movimiento" disabled={!props.editar}>
                <IconButton aria-label="delete" onClick={() => setOpen(true)}>
                    <AddIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Agregar Movimiento</DialogTitle>
                <form onSubmit={event => addMovement(event)}>                
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                            <TextField
                                required
                                id="fecha"
                                fullWidth
                                label="Fecha"
                                type="date"
                                value={fecha}
                                onChange={event => setFecha(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm={8}>
                            <TextField
                                required
                                margin="dense"
                                required
                                id="concepto"
                                label="Concepto"
                                type="text"
                                value={concepto}
                                onChange={event => setConcepto(event.target.value)}
                                fullWidth
                            />
                        </Grid>

                        <Grid item xs={12} sm={5}>
                            <TextField
                                select
                                required
                                fullWidth
                                id="tipo"
                                margin="dense"
                                label="Tipo de Movimiento"
                                value={tipo}
                                onChange={event => setTipo(event.target.value)}
                            >
                                <MenuItem value="abono">
                                    <span>Abono</span>
                                </MenuItem>
                                <MenuItem value="cargo">
                                    <span>Cargo</span>
                                </MenuItem>
                            </TextField>
                        </Grid>
                        <Grid item xs={12} sm={7}>
                            <TextField
                                margin="dense"
                                required
                                id="cantidad"
                                label="Cantidad"
                                type="number"
                                value={cantidad}
                                type="number"
                                onChange={event => setCantidad(event.target.value)}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancelar
                    </Button>
                    <Button type="submit" color="primary">
                        Agregar
                    </Button>
                </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}
