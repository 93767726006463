//React
import React from 'react';

//Components
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Tooltip from '@material-ui/core/Tooltip';
import Divider from '@material-ui/core/Divider';

//Icons
import Estudiantes from '@material-ui/icons/People';
import AccountCircle from '@material-ui/icons/PowerSettingsNew';
import MoreIcon from '@material-ui/icons/MoreVert';
import AdminIcon from '@material-ui/icons/School';

//Other Reqs
import * as store from '../../utils/localStore';
import classNames from 'classnames';
import { NavLink } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
  menuButton: {
    marginRight: '12px',
  },
});

const MenuBar = (props) => {
  const { classes, match } = props;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [nombre, setNombre] = React.useState('');
  const [rol, setRol] = React.useState('');
  const [opcionSeleccionada, setOpcionSeleccionada] = React.useState(
    window.location.href.split('/')[5]
  );
  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  function handleProfileMenuOpen(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileMenuClose() {
    setMobileMoreAnchorEl(null);
  }

  function handleMenuClose() {
    setAnchorEl(null);
    handleMobileMenuClose();
  }

  function handleMobileMenuOpen(event) {
    setMobileMoreAnchorEl(event.currentTarget);
  }

  function logOut() {
    store.clear();
    setAnchorEl(null);
    props.history.push('/login');
  }

  React.useEffect(() => {
    if (store.getObjects('session')) {
      setNombre(store.getObjects('session').usuario.email);
      setRol(store.getObjects('session').usuario.rol);
    }
  }, []);

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}>
      <Typography style={{ margin: '15px' }}>
        {nombre} - {rol}
      </Typography>
      <Divider />
      <MenuItem onClick={logOut}>Cerrar Sesión</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}>
      <MenuItem>
        <NavLink to={`${match.url}/estudiantes`}>
          <IconButton aria-label='show 4 new mails' color='default'>
            <Estudiantes />
          </IconButton>
          <p>Estudiantes</p>
        </NavLink>
      </MenuItem>
      <MenuItem onClick={logOut}>
        <IconButton
          aria-label='account of current user'
          aria-controls='primary-search-account-menu'
          aria-haspopup='true'
          color='default'>
          <AccountCircle />
        </IconButton>
        <p>Cerrar Sesión</p>
      </MenuItem>
    </Menu>
  );

  return (
    <div className={classes.grow}>
      <AppBar position='static' style={{ height: '72px', paddingTop: '5px' }}>
        <Toolbar>
          <NavLink to={`${match.url}/inicio`}>
            <IconButton
              edge='start'
              className={classes.menuButton}
              color='default'
              aria-label='open drawer'>
              <AdminIcon />
            </IconButton>
          </NavLink>
          <Typography
            className={classes.title}
            variant='h6'
            color='inherit'
            noWrap>
            Control Escolar
          </Typography>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
            <NavLink to={`${match.url}/estudiantes`}>
              <Tooltip title='Estudiantes'>
                <IconButton
                  color='default'
                  onClick={() => setOpcionSeleccionada('estudiantes')}>
                  <Estudiantes
                    className={classNames({
                      selectedIcon: opcionSeleccionada == 'estudiantes',
                    })}
                  />
                </IconButton>
              </Tooltip>
            </NavLink>
            <Tooltip title='Cerrar Sesión'>
              <IconButton
                edge='end'
                aria-controls={menuId}
                aria-haspopup='true'
                onClick={handleProfileMenuOpen}
                color='default'>
                <AccountCircle />
              </IconButton>
            </Tooltip>
          </div>
          <div className={classes.sectionMobile}>
            <IconButton
              aria-label='show more'
              aria-controls={mobileMenuId}
              aria-haspopup='true'
              onClick={handleMobileMenuOpen}
              color='default'>
              <MoreIcon />
            </IconButton>
          </div>
        </Toolbar>
      </AppBar>
      {renderMobileMenu}
      {renderMenu}
    </div>
  );
};

export default withStyles(styles)(MenuBar);
