import React from 'react';
import { Box, Typography, TextField, MenuItem } from '@material-ui/core';
import { TutorInfo } from './TutorInfo';

export const StudentInfo = ({
  classes,
  editedStudent,
  editar,
  fechaNacimiento,
  handleAddress,
  handleText,
  handleSelect,
  handleFecha,
  handleTutor,
}) => {
  const currentLvelItems = [
    { value: '', label: 'Seleccione un valor' },
    { value: 'PRE01', label: 'Prescolar 1' },
    { value: 'PRE02', label: 'Prescolar 2' },
    { value: 'PRE03', label: 'Prescolar 3' },
    { value: 'PRI01', label: 'Primaria 1' },
    { value: 'PRI02', label: 'Primaria 2' },
    { value: 'PRI03', label: 'Primaria 3' },
    { value: 'PRI04', label: 'Primaria 4' },
    { value: 'PRI05', label: 'Primaria 5' },
    { value: 'PRI06', label: 'Primaria 6' },
    { value: 'SEC07', label: 'Secundaria 7' },
    { value: 'SEC08', label: 'Secundaria 8' },
    { value: 'SEC09', label: 'Secundaria 9' },
  ];
  const statusItems = [
    { value: '', label: 'Seleccione un valor' },
    { value: '0', label: 'Cuenta Creada' },
    { value: '1', label: 'Cuenta Verificada' },
    { value: '2', label: 'Inscrito Sin Pago' },
    { value: '3', label: 'Inscrito Con Pago' },
    { value: '4', label: 'Cierre solicitado' },
    { value: '5', label: 'Boleta Aprobada' },
    { value: '6', label: 'Baja' },
  ];

  return (
    <Box width='100%'>
      <Box display='flex' flexDirection='column' gridGap='8px'>
        <Typography variant='h6'>Datos personales</Typography>
        <Box
          display='grid'
          gridTemplateColumns='repeat(auto-fit, minmax(300px, 1fr))'
          gridGap='24px'>
          <TextField
            fullWidth
            disabled={!editar}
            id='nombres'
            label='Nombre(s)'
            value={editedStudent.nombres ?? ''}
            className={classes.textField}
            onChange={handleText}
          />
          <TextField
            fullWidth
            disabled={!editar}
            id='apellidoP'
            label='Apellido Paterno'
            value={editedStudent.apellidoP ?? ''}
            className={classes.textField}
            onChange={handleText}
          />
          <TextField
            fullWidth
            disabled={!editar}
            id='apellidoM'
            label='Apellido Materno'
            value={editedStudent.apellidoM ?? ''}
            className={classes.textField}
            onChange={handleText}
          />
          <TextField
            fullWidth
            disabled={!editar}
            id='usuario'
            label='Matrícula'
            value={editedStudent.usuario ?? ''}
            className={classes.textField}
            onChange={handleText}
          />

          <TextField
            select
            fullWidth
            disabled={!editar}
            id='sexo'
            label='Sexo'
            name='sexo'
            value={editedStudent.sexo ?? ''}
            className={classes.textField}
            onChange={handleSelect}>
            <MenuItem value='M' name='sexo'>
              Masculino
            </MenuItem>
            <MenuItem value='F' name='sexo'>
              Femenino
            </MenuItem>
          </TextField>

          <TextField
            disabled={!editar}
            id='fechaNacimiento'
            name='fechaNacimiento'
            fullWidth
            label='Fecha de Nacimiento'
            type='date'
            value={fechaNacimiento}
            onChange={handleFecha}
            InputLabelProps={{ shrink: true }}
          />

          <TextField
            fullWidth
            disabled={!editar}
            id='curp'
            label='CURP'
            value={editedStudent.curp ?? ''}
            className={classes.textField}
            onChange={handleText}
          />

          <TextField
            fullWidth
            disabled={!editar}
            id='telefono'
            label='Teléfono'
            value={editedStudent.telefono ?? ''}
            className={classes.textField}
            onChange={handleText}
          />

          <TextField
            fullWidth
            select
            disabled={!editar}
            id='nivelActual'
            name='nivelActual'
            label='Nivel Actual'
            value={editedStudent.nivelActual ?? ''}
            className={classes.textField}
            onChange={handleSelect}>
            {currentLvelItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            fullWidth
            disabled={!editar}
            id='emailInstitucional'
            label='Email Centro Educativo'
            value={editedStudent.emailInstitucional ?? ''}
            className={classes.textField}
            onChange={handleText}
          />

          <TextField
            fullWidth
            disabled={!editar}
            id='emailPersonal'
            label='Email Personal'
            value={editedStudent.emailPersonal ?? ''}
            className={classes.textField}
            onChange={handleText}
          />

          <TextField // select
            fullWidth
            disabled={!editar}
            id='centroEducativo'
            label='Centro Educativo'
            name='centroEducativo'
            className={classes.textField}
            onChange={handleText}
          />

          <TextField
            fullWidth
            disabled={!editar}
            id='password'
            label='Password'
            value={editedStudent.password ? editedStudent.password : ''}
            className={classes.textField}
            onChange={handleText}
          />

          <TextField
            fullWidth
            select
            disabled={!editar}
            name='actaValidada'
            id='actaValidada'
            label='Acta Validada'
            value={editedStudent.actaValidada ?? ''}
            className={classes.textField}
            onChange={handleSelect}>
            <MenuItem value=''>
              <em>Ninguno</em>
            </MenuItem>
            <MenuItem value='S'>
              <span>Si</span>
            </MenuItem>
            <MenuItem value='N'>
              <span>No</span>
            </MenuItem>
          </TextField>

          <TextField
            fullWidth
            select
            disabled={!editar}
            id='estado'
            name='estado'
            label='Estado'
            value={editedStudent.estado ?? ''}
            className={classes.textField}
            onChange={handleSelect}>
            {statusItems.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Box padding='24px 0' display='flex' flexDirection='column' gridGap='8px'>
        <Typography variant='h6'>Dirección</Typography>
        <Box
          display='grid'
          gridTemplateColumns='repeat(auto-fit, minmax(400px, 1fr))'
          gridGap='16px'>
          <TextField
            fullWidth
            disabled={!editar}
            id='street'
            label='Dirección'
            value={editedStudent.direccion?.street ?? ''}
            className={classes.textField}
            onChange={(e) => handleAddress(e.target.value, 'street')}
          />
          <TextField
            fullWidth
            disabled={!editar}
            id='city'
            label='Ciudad'
            value={editedStudent.direccion?.city ?? ''}
            className={classes.textField}
            onChange={(e) => handleAddress(e.target.value, 'city')}
          />
          <TextField
            fullWidth
            disabled={!editar}
            id='zip'
            label='Código Postal'
            value={editedStudent.direccion?.zip ?? ''}
            className={classes.textField}
            onChange={(e) => handleAddress(e.target.value, 'zip')}
          />
          <TextField
            fullWidth
            disabled={!editar}
            id='country'
            label='País'
            value={editedStudent.direccion?.country ?? ''}
            className={classes.textField}
            onChange={(e) => {
              handleAddress(e.target.value, 'country');
            }}
          />
        </Box>
      </Box>
      <TutorInfo
        tutor={editedStudent.tutor}
        handleTutor={handleTutor}
        editar={editar}
        classes={classes}
      />
    </Box>
  );
};
