import { Grid } from '@material-ui/core';
import { HashLoader } from 'react-spinners';
import React from 'react';

export const GlobalLoader = () => {
  const styles = {
    position: 'absolute',
    width: '100%',
    height: '100%',
    background: 'rgba(0,0,0,0.5)',
    display: 'flex',
  };
  return (
    <Grid style={styles} container justifyContent='center' alignItems='center'>
      <Grid item>
        <HashLoader color='#36d7b7' size={64} margin={4} />
      </Grid>
    </Grid>
  );
};
