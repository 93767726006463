//Urls de Servidores.
const centrosServer = 'https://centros.prepaenlinea.mx/api/';

//Informacion del banco y referencia de pagos.
const bankConf = {
  cieNo: '1047981',
  clabe: '012830001872498968',
  rfc: 'EPV1109074E3',
};

const generateAlumnReference = (level, consecutive, year, cicle) => {
  var finalConsecutive = '' + consecutive;
  var zeros = 5 - finalConsecutive.length;
  var unic = Math.floor(Math.random() * 10);
  for (var i = 0; i < zeros; i++) finalConsecutive = '0' + finalConsecutive;
  return level + year[2] + year[3] + cicle + finalConsecutive + unic;
};

const generateReference = (razon, contador) => {
  var pref = razon;
  var mult = 2;
  var fecha = new Date();
  var numb =
    '' +
    pref +
    fecha.getDate() +
    (fecha.getMonth() + 1) +
    fecha.getFullYear() +
    fecha.getHours() +
    fecha.getMinutes() +
    fecha.getSeconds();
  var size = numb.length;
  var total = 0;
  for (var i = size; i >= 1; i--) {
    var res = parseInt(numb[i - 1]) * mult;
    var tot = res;
    if (res > 9)
      tot = parseInt(res.toString()[0]) + parseInt(res.toString()[1]);
    total += tot;
    mult++;
    if (mult > 2) mult = 1;
  }
  var max = parseInt(total.toString()[0]) + 1;
  var ref = max * 10 - total >= 10 ? 0 : max * 10 - total;
  return '' + numb + ref;
};

export { generateReference, generateAlumnReference, bankConf };
