import React from 'react';
import axios from 'axios';
import * as store from '../../utils/localStore';
import materias from '../../utils/materias';

export const useControlActions = ({
  estudiantes,
  editedStudent,
  setLoading,
  setEstudianteIndex,
  setSnackbar,
}) => {
  const getGrade = (nivel) => {
    switch (nivel) {
      case 'PRE01':
        return materias[0].materias;
      case 'PRE02':
        return materias[1].materias;
      case 'PRE03':
        return materias[2].materias;
      case 'PRI01':
        return materias[3].materias;
      case 'PRI02':
        return materias[4].materias;
      case 'PRI03':
        return materias[5].materias;
      case 'PRI04':
        return materias[6].materias;
      case 'PRI05':
        return materias[7].materias;
      case 'PRI06':
        return materias[8].materias;
      case 'SEC07':
        return materias[9].materias;
      case 'SEC08':
        return materias[10].materias;
      default:
        return materias[11].materias;
    }
  };
  const getCSV = () => {
    let csvBody =
      'Matrícula;Correo Electrónico;Nombre;Apellido Paterno;Apellido Materno;Sexo;País;Ciudad;Teléfono;Fecha de Nacimiento;Correo de Tutor;Nombre de Tutor\n';

    for (let i in estudiantes) {
      let e = estudiantes[i];
      let fecha = new Date(e.fechaNacimiento);
      let f =
        fecha.getDate() +
        '/' +
        (fecha.getMonth() + 1) +
        '/' +
        fecha.getFullYear();

      csvBody +=
        e.usuario +
        ';' +
        e.emailPersonal +
        ';' +
        e.nombres +
        ';' +
        e.apellidoP +
        ';' +
        e.apellidoM +
        ';' +
        e.sexo +
        ';' +
        e.direccion.country +
        ';' +
        e.direccion.city +
        ';' +
        e.telefono +
        ';' +
        f +
        ';' +
        e.tutor.email +
        ';' +
        e.tutor.nombre +
        ' \n';
    }

    let hiddenElement = document.createElement('a');

    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvBody);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'estudiantes.csv';
    hiddenElement.click();
  };

  const getCSVMoodle = () => {
    let csvBody = getCSVHeader();

    for (let i in estudiantes) {
      let e = estudiantes[i];

      if (e.nivelActual) {
        csvBody += getCSVRow(e);
      }
    }

    downloadCSV(csvBody, 'estudiantes.csv');
  };

  const getCSVHeader = () => {
    return 'username;password;firstname;lastname;email;country;city;course1;course2;course3;course4;course5;course6;course7;course8;course9;course10;course11;course12;course13;course14\n';
  };

  const getCSVRow = (e) => {
    const mat = getGrade(e.nivelActual);
    let row =
      e.usuario +
      ';' +
      e.password +
      ';' +
      e.nombres +
      ';' +
      e.apellidoP +
      ' ' +
      e.apellidoM +
      ';' +
      e.emailPersonal +
      ';' +
      e.direccion.country +
      ';' +
      e.direccion.city +
      ';';
    if (mat)
      for (let m in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]) {
        row += mat[m] ? mat[m].clave : '';
        if (m != 13) row += ';';
      }

    row += '\n';
    return row;
  };

  const downloadCSV = (csvBody, filename) => {
    let hiddenElement = document.createElement('a');
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvBody);
    hiddenElement.target = '_blank';
    hiddenElement.download = filename;
    hiddenElement.click();
  };

  const getCSVCurrent = () => {
    let csvBody =
      'Matrícula;Correo Electrónico;Nombre;Apellido Paterno;Apellido Materno;Sexo;País;Ciudad;Teléfono;Fecha de Nacimiento;Correo de Tutor;Nombre de Tutor\n';
    let e = editedStudent;
    let fecha = new Date(e.fechaNacimiento);
    let f =
      fecha.getDate() +
      '/' +
      (fecha.getMonth() + 1) +
      '/' +
      fecha.getFullYear();

    csvBody +=
      e.usuario +
      ';' +
      e.emailPersonal +
      ';' +
      e.nombres +
      ';' +
      e.apellidoP +
      ';' +
      e.apellidoM +
      ';' +
      e.sexo +
      ';' +
      e.direccion.country +
      ';' +
      e.direccion.city +
      ';' +
      e.telefono +
      ';' +
      f +
      ';' +
      e.tutor.email +
      ';' +
      e.tutor.nombre +
      ' \n';
    const hiddenElement = document.createElement('a');
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvBody);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'estudiantes.csv';
    hiddenElement.click();
  };

  const getCSVMoodleCurrent = () => {
    let csvBody =
      'username;password;firstname;lastname;email;country;city;course1;course2;course3;course4;course5;course6;course7;course8;course9;course10;course11;course12;course13;course14\n';
    let e = editedStudent;

    const pais =
      e.direccion.country === undefined ? 'México' : e.direccion.country;

    if (e.nivelActual) {
      const mat = getGrade(e.nivelActual);
      csvBody +=
        e.usuario +
        ';' +
        e.password +
        ';' +
        e.nombres +
        ';' +
        e.apellidoP +
        ' ' +
        e.apellidoM +
        ';' +
        e.emailPersonal +
        ';' +
        pais +
        ';' +
        e.direccion.city +
        ';';
      if (mat)
        for (let m in [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]) {
          csvBody += mat[m] ? mat[m].clave : '';
          if (m != 13) csvBody += ';';
        }

      csvBody += '\n';
    }
    let hiddenElement = document.createElement('a');
    hiddenElement.href =
      'data:text/csv;charset=utf-8,' + encodeURIComponent(csvBody);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'estudiantes.csv';
    hiddenElement.click();
  };

  function uploadCSV(event) {
    let usuarios = [];
    let reader = new FileReader();
    reader.onload = function () {
      let rows = reader.result.split('\n');
      let headers = rows[0].split(';');
      let usuarioPos = 0;
      for (let i = 1; i < rows.length - 1; i++) {
        let usuario = rows[i].split(';');
        usuarios[usuarioPos] = {
          nombres: '',
          moodleId: '',
          apellidoP: '',
          apellidoM: '',
          usuario: '',
          boletaImpresa: false,
          constancia: '',
          password: '',
          curp: '',
          observaciones: '',
          marcarReg: '',
          estado: '1',
          direccion: {
            country: '',
            state: '',
            city: '',
            zip: '',
            neighborhood: '',
            ext: '',
            int: '',
            street: '',
          },
          sexo: '',
          telefono: '',
          intentosPago: [],
          emailPersonal: '',
          emailInstitucional: '',
          fechaNacimiento: null,
          tieneBeca: false,
          historialAcademico: [],
          nivelActual: '',
          emailHash: '',
          actaValidada: '',
          fotoValidada: '',
          tutor: {
            nombre: '',
            email: '',
            telefono: '',
          },
          historialPagos: {
            abonos: [],
            cargos: [],
          },
          factura: {
            requiereFactura: false,
            id: '',
            datosFacturacion: {
              legal_name: '',
              email: '',
              tax_id: '',
              address: {
                country: '',
                state: '',
                city: '',
                zip: '',
                neighborhood: '',
                ext: '',
                int: '',
                street: '',
              },
            },
            facturas: [],
          },
        };
        for (let j = 0; j < usuario.length; j++) {
          switch (j) {
            case 1:
              usuarios[usuarioPos].nombres = usuario[j];
              break;
            case 2:
              usuarios[usuarioPos].apellidoM = usuario[j].split(' ')[1];
              usuarios[usuarioPos].apellidoP = usuario[j].split(' ')[0];
              break;
            case 3:
              usuarios[usuarioPos].usuario = usuario[j];
              break;
            case 4:
              usuarios[usuarioPos].password = usuario[j];
              break;
            case 5:
              usuarios[usuarioPos].foto = usuario[j];
              break;
            case 9:
              usuarios[usuarioPos].tutor.nombre = usuario[j];
              break;
            case 10:
              usuarios[usuarioPos].centroEducativo = usuario[j];
              break;
            case 11: {
              let fl = usuario[j].split('/');
              let f = new Date();
              f.setMonth(fl[0] - 1);
              f.setDate(fl[1]);
              f.setFullYear(fl[2]);
              usuarios[usuarioPos].fechaNacimiento = f;
              break;
            }
            case 12:
              usuarios[usuarioPos].sexo = usuario[j];
              break;
            case 13:
              usuarios[usuarioPos].marcarReg = usuario[j];
              break;
            case 14:
              usuarios[usuarioPos].direccion.country = usuario[j];
              break;
            case 15:
              usuarios[usuarioPos].observaciones = usuario[j];
              break;
            case 16:
              usuarios[usuarioPos].emailPersonal = usuario[j];
              break;
            case 17:
              usuarios[usuarioPos].actaValidada = usuario[j];
              break;
            case 18:
              usuarios[usuarioPos].tutor.email = usuario[j];
              break;
            case 20:
              usuarios[usuarioPos].telefono = usuario[j];
              break;
            case 21:
              usuarios[usuarioPos].fotoValidada = usuario[j];
              break;
            case 22:
              usuarios[usuarioPos].nivelActual = usuario[j];
              break;
            // case 22: usuarios[usuarioPos].nivelActual = usuario[j]
            // break
          }
        }
        let now = new Date();
        const newMatricula =
          now.getDate() +
          '' +
          now.getMonth() +
          '' +
          now.getFullYear() +
          now.getHours() +
          now.getMinutes() +
          now.getSeconds();
        if (usuarios[usuarioPos].usuario == '')
          usuarios[usuarioPos].usuario = '25' + newMatricula;

        if (usuarios[usuarioPos].password == '')
          usuarios[usuarioPos].password =
            usuarios[usuarioPos].nombres[0] +
            usuarios[usuarioPos].nombres[1] +
            usuarios[usuarioPos].nombres[2] +
            usuarios[usuarioPos].apellidoP[0] +
            usuarios[usuarioPos].apellidoP[1] +
            '.' +
            now.getDate() +
            now.getMonth();

        if (usuario[8] != '') {
          usuarios[usuarioPos].intentosPago.push({
            fecha: new Date(),
            concepto:
              usuarios[usuarioPos].nivelActual +
              '/' +
              usuarios[usuarioPos].usuario,
            orderId: usuario[8],
            cantidad: '2000',
            moneda: 'MXN',
          });
          usuarios[usuarioPos].estado = 3;
        } else usuarios[usuarioPos].estado = 2;
        usuarios[usuarioPos].historialPagos.cargos.push({
          fecha: new Date(),
          concepto: 'Inscripcion',
          cantidad: '2000',
          moneda: 'MXN',
        });
        usuarioPos++;
      }
      if (
        confirm(
          'Están a punto de agregarse ' +
            usuarios.length +
            ' usuarios en la plataforma. ¿Desea continuar?'
        )
      ) {
        setLoading(true);
        getData(usuarios, 0, (finales) => {
          alert('Se han terminado de subir los usuarios, refresque la página.');
          setLoading(false);
          setUploading('');
        });
      }
    };
    reader.readAsText(event.target.files[0], 'UTF-8');
  }

  function sendValidacion() {
    setLoading(true);
    axios({
      method: 'POST',
      url:
        process.env.EDUCAZION_SERVER +
        'estudiantes/sendValidation/' +
        editedStudent.usuario,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + store.getObject('token'),
      },
    })
      .then((response) => {
        setSnackbar({
          open: true,
          variant: 'success',
          text: 'Se ha reenviado de manera exitosa.',
        });
        setEstudianteIndex(0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnackbar({
          open: true,
          variant: 'error',
          text: 'Ha habido un error.',
        });
      });
  }

  function sendBienvenida() {
    setLoading(true);
    axios({
      method: 'POST',
      url:
        process.env.EDUCAZION_SERVER +
        'estudiantes/sendBienvenida/' +
        editedStudent.usuario,
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + store.getObject('token'),
      },
    })
      .then((response) => {
        setSnackbar({
          open: true,
          variant: 'success',
          text: 'Se ha enviado de manera exitosa.',
        });
        setEstudianteIndex(0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnackbar({
          open: true,
          variant: 'error',
          text: 'Ha habido un error.',
        });
      });
  }

  const uploadActa = () => {
    let file = document.getElementById('acta').files[0];
    let formData = new FormData();
    formData.append('documento', file);
    setLoading(true);
    axios({
      method: 'POST',
      url: `${process.env.EDUCAZION_SERVER}estudiantes/${editedStudent.usuario}/acta`,
      responseType: 'json',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Token ' + store.getObject('token'),
      },
    })
      .then((response) => {
        setSnackbar({
          open: true,
          variant: 'success',
          text: 'El acta se ha subido de manera exitosa.',
        });
        setEstudianteIndex(0);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setSnackbar({
          open: true,
          variant: 'error',
          text: 'Ha habido un error.',
        });
      });
  };

  return {
    getCSV,
    getCSVMoodle,
    getCSVCurrent,
    getCSVMoodleCurrent,
    uploadCSV,
    sendValidacion,
    sendBienvenida,
    uploadActa,
  };
};
