//React
import React from 'react';

//Components
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Tooltip from '@material-ui/core/Tooltip';
import NuevoEstudiante from '../estudiantes/NuevoEstudiante';

//Icons
import Clear from '@material-ui/icons/Clear';
import Save from '@material-ui/icons/Save';

//Other Reqs
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    width: 'auto',
    height: '64px',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
    backgroundColor: 'rgba(230,230,230, 1)',
    position: 'fixed',
    bottom: 0,
    right: 0,
    transition: 'all .5s ease-in-out',
  },
  closed: {
    bottom: '-40px !important',
  },
  form: {
    display: 'contents !important',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
    width: '100%',
    margin: 0,
  },
});

const BarraHerramientas = (props) => {
  const [open, setOpen] = React.useState(true);

  React.useEffect(() => {
    setTimeout(() => setOpen(true), 1000);
  }, [true]);

  const { classes } = props;

  return (
    <Grid
      onMouseEnter={() => setOpen(true)}
      onMouseLeave={() => setOpen(true)}
      className={classNames(classes.root, { [classes.closed]: !open })}
      container
      justifyContent='center'
      alignItems='center'>
      <Grid item xs>
        {props.editar ? (
          <Tooltip title='Guardar Estudiante'>
            <IconButton
              aria-label='delete'
              className={classes.margin}
              size='medium'
              onClick={props.saveEstudiante}>
              <Save fontSize='inherit' />
            </IconButton>
          </Tooltip>
        ) : (
          <NuevoEstudiante
            setReload={props.setReload}
            reload={props.reload}
            newEstudiante={props.newEstudiante}
            setSnackbar={props.setSnackbar}
          />
        )}
      </Grid>
      {props.editar ? (
        <Grid item xs>
          <Tooltip title='Restaurar'>
            <IconButton
              aria-label='delete'
              className={classes.margin}
              size='medium'
              onClick={props.restaurar}>
              <Clear fontSize='inherit' />
            </IconButton>
          </Tooltip>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(BarraHerramientas);
