import React from 'react';
import * as R from 'ramda';
import FotografiaDialog from './FotografiaDialog';

import * as store from '../../utils/localStore';
import { Box, Button } from '@material-ui/core';
import axios from 'axios';

export const UPLOAD_PHOTO = (data, matricula, config) => {
  return axios.post(
    `${process.env.EDUCAZION_SERVER}estudiantes/${matricula}/portrait`,
    data,
    config
  );
};

const Fotografia = ({ setSnackbar, estudiante }) => {
  const [subirFotoDialog, setSubirFotoDialog] = React.useState(false);
  const [dialogStepper, setDialogStepper] = React.useState(0);
  const [fotografia, setFotografia] = React.useState(null);
  const [loading, setLoading] = React.useState(false);

  const handleImage = () => {
    const file = document.getElementById('imginput').files[0];
    if (
      !R.includes(file.type, [
        'image/jpeg',
        'image/jpg',
        'image/png',
        'image/bmp',
      ])
    ) {
      setSnackbar({
        open: true,
        variant: 'error',
        text: 'Los formatos soportados para este archivo son: JPEG, JPG, PNG, BMP',
      });
    } else if (file.size > 2000000) {
      setSnackbar({
        open: true,
        text:
          'El peso de la fotografía no puede ser mayor a 2mb. (La suya pesa ' +
          file.size / 1000000 +
          'mb)',
        variant: 'error',
      });
    } else {
      setFotografia({
        url: URL.createObjectURL(file),
        raw: file,
      });
      setLoading(true);
    }
  };

  const uploadImage = async () => {
    try {
      const formData = new FormData();
      formData.append('fotografia', fotografia?.raw);
      const token = store.getObject('token');
      const config = {
        headers: {
          Authorization: `Token ${token}`,
        },
      };

      await UPLOAD_PHOTO(formData, estudiante?.usuario, config);
      setSnackbar({
        open: true,
        text: 'La foto ha sido actualizada con éxito',
        variant: 'success',
      });
    } catch (error) {
      setSnackbar({
        open: true,
        text: 'Ocurrió un error inesperado, por favor inténtalo más tarde',
        variant: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box>
      <Box
        display='flex'
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
        width='200px'
        margin='0 auto 16px auto'
        gridGap='16px'>
        <img
          src={`${process.env.BUCKET_S3}/${estudiante?.foto}`}
          style={{
            width: '100%',
            height: '200px',
            objectFit: 'cover',
          }}
        />
        <Button
          variant='contained'
          color='primary'
          onClick={() => setSubirFotoDialog(true)}>
          Editar Fotografía
        </Button>
      </Box>
      <FotografiaDialog
        openDialog={subirFotoDialog}
        cancelar={() => {
          setSubirFotoDialog(false);
          setFotografia(null);
          setDialogStepper(0);
        }}
        finalizar={() => {
          setSubirFotoDialog(false);
          setFotografia(null);
          setDialogStepper(0);
        }}
        setFotografia={setFotografia}
        activeStep={dialogStepper}
        fotografia={fotografia}
        setActiveStep={setDialogStepper}
        uploadImage={uploadImage}
        handleImage={handleImage}
        loading={loading}
      />
    </Box>
  );
};

export default Fotografia;
