import * as store from '../../utils/localStore';
import axios from 'axios';

export const useSaveStudent = ({
  setLoading,
  setReload,
  setSnackbar,
  reload,
  estudiante,
}) => {
  function saveEstudiante() {
    setLoading(true);
    axios({
      method: 'POST',
      url: process.env.EDUCAZION_SERVER + 'estudiantes/' + estudiante.usuario,
      responseType: 'json',
      data: {
        ...estudiante,
        pais: estudiante.direccion.country,
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + store.getObject('token'),
      },
    })
      .then(() => {
        setLoading(false);
        setSnackbar({
          open: true,
          variant: 'success',
          text: 'Se ha guardado de manera exitosa.',
        });
        setReload(!reload);
        window.location.reload();
      })
      .catch(() => {
        setLoading(false);
        setSnackbar({
          open: true,
          variant: 'error',
          text: 'Ha habido un error.',
        });
      });
  }
  return {
    saveEstudiante,
  };
};
