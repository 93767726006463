// React
import React from 'react';

// Axios for HTTP Requests
import axios from 'axios';

// Components
import SnackBar from '../SnackBar';
import { Box } from '@material-ui/core';
import { PhotoComponent } from './EstudianteComponents/PhotoComponent';
import { StudentInfo } from './EstudianteComponents/StudentInfo';
import { AcademicInfo } from './EstudianteComponents/AcademicInfo';
import { ActionsBar } from './EstudianteComponents/ActionsBar';

// Other Reqs
import * as store from '../../utils/localStore';
const { fromJS } = require('immutable');
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    padding: '10px 30px 30px 0px',
    [theme.breakpoints.down('md')]: {
      padding: '0px 30px 30px 30px',
    },
    overflow: 'auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
  picture: {
    maxWidth: '100%',
    width: 'auto',
    height: '200px',
  },
  picFrame: {
    textAlign: 'center',
    width: '60%',
    border: '2px solid rgb(230,230,230)',
    margin: '5% 0% 5% 20%',
    minHeight: '200px',
  },
  logo: {
    width: '120px',
  },
});

const Estudiante = (props) => {
  const [historialAcademico, setHistorialAcademico] = React.useState([]);
  const [historialPagos, setHistorialPagos] = React.useState({});
  const [fechaNacimiento, setFechaNacimiento] = React.useState('0001-01-01');
  const [uploading, setUploading] = React.useState('');
  const [fechaEntrada, setFechaEntrada] = React.useState('0001-01-01');

  const {
    rol,
    editar,
    setEditar,
    reloadEstudiante,
    snackbar,
    setSnackbar,
    editedStudent,
    setEditedStudent,
  } = props;

  const handleSelect = (event) => {
    const e = fromJS(editedStudent);
    const ef = e.set(event.target.name, event.target.value);
    setEditedStudent(ef.toJS());
  };

  const handleText = (event) => {
    const e = fromJS(editedStudent);
    const ef = e.set(event.target.id, event.target.value);
    setEditedStudent(ef.toJS());
  };

  const handleAddress = (value, paramName) => {
    const e = fromJS(editedStudent);
    const ef = e.setIn(['direccion', paramName], value);
    setEditedStudent(ef.toJS());
  };

  const handleTutor = (value, paramName) => {
    const e = fromJS(editedStudent);
    const ef = e.setIn(['tutor', paramName], value);
    setEditedStudent(ef.toJS());
  };

  const handleFecha = (event) => {
    setFechaNacimiento(event.target.value);
    const e = fromJS(editedStudent);
    const ef = e.set(event.target.id, new Date(event.target.value));
    setEditedStudent(ef.toJS());
  };

  const handleFechaRoyal = (event) => {
    const e = fromJS(editedStudent);
    const ef = e.set(event.target.id, new Date(event.target.value));
    setEditedStudent(ef.toJS());
  };

  const getData = (estudiantes, index, next) => {
    setUploading('Cargando ' + index + ' de ' + estudiantes.length);
    if (index === estudiantes.length) return next(estudiantes);

    return axios({
      method: 'POST',
      url: process.env.EDUCAZION_SERVER + 'estudiantes/nuevo',
      responseType: 'json',
      data: {
        estudiante: estudiantes[index],
      },
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + store.getObject('token'),
      },
    }).then((response) => {
      return getData(estudiantes, index + 1, next);
    });
  };

  React.useEffect(() => {
    props.setLoading(true);
    let eMap = fromJS(props.estudiante);
    let fechaN = new Date(eMap.get('fechaNacimiento'));
    let dia = fechaN.getDate() < 10 ? '0' + fechaN.getDate() : fechaN.getDate();
    let mes =
      fechaN.getMonth() + 1 < 10
        ? '0' + (fechaN.getMonth() + 1)
        : fechaN.getMonth() + 1;

    let fechaE = new Date(eMap.get('comienzaCiclo'));
    let diaE =
      fechaE.getDate() < 10 ? '0' + fechaE.getDate() : fechaE.getDate();
    let mesE =
      fechaE.getMonth() + 1 < 10
        ? '0' + (fechaE.getMonth() + 1)
        : fechaE.getMonth() + 1;
    setFechaEntrada(fechaE.getFullYear() + '-' + mesE + '-' + diaE);
    setFechaNacimiento(fechaN.getFullYear() + '-' + mes + '-' + dia);
    setEditedStudent(eMap.toJS());
    setHistorialAcademico(eMap.get('historialAcademico').toJS());
    setHistorialPagos(eMap.get('historialPagos').toJS());
    props.setLoading(false);
  }, [props.estudiante, reloadEstudiante]);

  const { classes } = props;

  return (
    <Box>
      <ActionsBar
        editedStudent={editedStudent}
        estudiantes={props.estudiantes}
        uploading={uploading}
        setLoading={props.setLoading}
        setEstudianteIndex={props.setEstudianteIndex}
        setEditar={setEditar}
        editar={editar}
        rol={rol}
        classes={classes}
        loading={props.loading}
        setSnackbar={setSnackbar}
      />
      <Box
        display='flex'
        padding='32px'
        gridGap='32px'
        borderBottom='1px solid #e0e0e0'>
        <PhotoComponent
          editedStudent={editedStudent}
          estudiante={props.estudiante}
          setSnackbar={setSnackbar}
        />
        <StudentInfo
          classes={classes}
          editedStudent={editedStudent}
          editar={editar}
          fechaNacimiento={fechaNacimiento}
          handleAddress={handleAddress}
          handleFecha={handleFecha}
          handleSelect={handleSelect}
          handleText={handleText}
          handleTutor={handleTutor}
        />
      </Box>
      <AcademicInfo
        editedStudent={editedStudent}
        handleFechaRoyal={handleFechaRoyal}
        handleSelect={handleSelect}
        handleText={handleText}
        reload={props.reload}
        setReload={props.setReload}
        editar={editar}
        fechaEntrada={fechaEntrada}
        historialAcademico={historialAcademico}
        historialPagos={historialPagos}
        setEditedStudent={setEditedStudent}
        setHistorialPagos={setHistorialPagos}
        setSnackbar={setSnackbar}
      />
      <SnackBar
        open={snackbar.open}
        variant={snackbar.variant}
        text={snackbar.text}
        handleClose={setSnackbar}
      />
    </Box>
  );
};

export default withStyles(styles, { withTheme: true })(Estudiante);
