import * as R from 'ramda';
// React
import React from 'react';
import axios from 'axios';

// Components
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  Menu,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { DatePicker } from '@material-ui/pickers';

// Other Reqs
import { withStyles } from '@material-ui/core/styles';

import * as store from '../../utils/localStore';
import { downloadBoleta } from '../../utils/downloadGrades';
import { GlobalLoader } from '../GlobalLoader';

const grades = {
  PRE01: '1er Grado de Preescolar',
  PRE02: '2do Grado de Preescolar',
  PRE03: '3er Grado de Preescolar',
  PRI01: '1er Grado de Primaria (1ro)',
  PRI02: '2do Grado de Primaria (2do)',
  PRI03: '3er Grado de Primaria (3ro)',
  PRI04: '4to Grado de Primaria (4to)',
  PRI05: '5to Grado de Primaria (5to)',
  PRI06: '6to Grado de Primaria (6to)',
  SEC07: '1er Grado de Secundaria (7mo)',
  SEC08: '2do Grado de Secundaria (8vo)',
  SEC09: '3er Grado de Secundaria (9no)',
};

const getGrade = (nivel) => {
  return grades[nivel];
};

const styles = (theme) => ({
  root: {
    padding: '30px',
    overflow: 'auto',
    height: 'auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
});

const AgregarMateria = ({ open, handleClose, saveMateria }) => {
  const [data, setData] = React.useState({
    nombre: '',
    clave: '',
    calificacion: 5,
  });
  return (
    <Dialog open={open} size='' maxWidth='lg'>
      <DialogTitle>Añadir materia</DialogTitle>
      <DialogContent>
        <Grid item container spacing={3} alignItems='stretch'>
          <Grid item xs={12} md={6}>
            <Typography variant='body1'>Nombre</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='outlined'
              label='Nombre'
              value={data.nombre}
              onChange={(event) =>
                setData({ ...data, nombre: event.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='body1'>Clave</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              variant='outlined'
              label='Clave'
              value={data.clave}
              onChange={(event) =>
                setData({ ...data, clave: event.target.value })
              }
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant='body1'>Calificación</Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth>
              <InputLabel id='select-calificacion'>Calificación</InputLabel>
              <Select
                id='calificacion-select'
                labelId='select-calificacion'
                value={data.calificacion}
                variant='outlined'
                onChange={(event) => {
                  setData({
                    ...data,
                    calificacion: event.target.value,
                  });
                }}>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant='contained' color='primary' onClick={saveMateria(data)}>
          Aceptar
        </Button>
        <Button variant='contained' color='secondary' onClick={handleClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const Estudiante = (props) => {
  const [ciclos, setCiclos] = React.useState([]);
  const [cicloSeleccionado, setCicloSeleccionado] = React.useState({
    materias: [],
  });
  const [selected, setSelected] = React.useState('');
  const [comentario, setComentario] = React.useState('');
  const [fecha, setFecha] = React.useState('');
  const [grade, setGrade] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElEs, setAnchorElEs] = React.useState(null);
  const [loadingBoleta, setLoadingBoleta] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleClickEs = (event) => {
    setAnchorElEs(event.currentTarget);
  };

  const handleCloseMenuEs = () => {
    setAnchorElEs(null);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (event) => {
    setGrade(event.target.value);
    const ciclo = R.find((c) => c._id === event.target.value)(ciclos);
    setCicloSeleccionado(ciclo);
    setFecha(new Date(ciclo.fecha));
  };

  const saveMateria = (cicloSeleccionado) => (materia) => () => {
    const nuevoCiclo = {
      materias: cicloSeleccionado.materias,
      autorizado: cicloSeleccionado.autorizado,
      aprobado: cicloSeleccionado.aprobado,
      fecha: cicloSeleccionado.fecha,
      periodo: cicloSeleccionado.periodo,
    };
    nuevoCiclo.materias.push(materia);
    nuevoCiclo.autorizado = false;
    nuevoCiclo.aprobado = false;
    setCicloSeleccionado(nuevoCiclo);
    setOpen(false);
  };

  const deleteMateria = (index) => () => {
    if (confirm('¿Está seguro de querer eliminar la materia?')) {
      const nuevoCiclo = {
        materias: cicloSeleccionado.materias,
        autorizado: cicloSeleccionado.autorizado,
        aprobado: cicloSeleccionado.aprobado,
        fecha: cicloSeleccionado.fecha,
        periodo: cicloSeleccionado.periodo,
      };
      nuevoCiclo.materias.splice(index, 1);
      nuevoCiclo.autorizado = false;
      nuevoCiclo.aprobado = false;
      setCicloSeleccionado(nuevoCiclo);
      alert('De click en Autorizar Boleta para continuar');
    }
  };

  React.useEffect(() => {
    if (props.estudiante.historialAcademico) {
      setCiclos(props.estudiante.historialAcademico);
    }
  }, [props.estudiante]);

  function handleSelect(event, i, date) {
    event.preventDefault();
    let nuevoCiclo = {
      materias: cicloSeleccionado.materias,
      autorizado: cicloSeleccionado.autorizado,
      aprobado: cicloSeleccionado.aprobado,
      fecha: cicloSeleccionado.fecha,
      periodo: cicloSeleccionado.periodo,
    };
    nuevoCiclo.materias[i].calificacion = parseInt(event.target.value);
    setCicloSeleccionado(nuevoCiclo);
  }

  const authorize = async () => {
    if (
      confirm(
        '¿Está seguro que ha terminado de corroborar las calificaciones del estudiante?'
      )
    ) {
      let newHistorial = ciclos;
      newHistorial[selected] = cicloSeleccionado;
      newHistorial[selected].comentario = comentario;
      newHistorial[selected].autorizado = true;
      newHistorial[selected].aprobado = false;
      const response = await axios.post(
        `${process.env.EDUCAZION_SERVER}estudiantes/autoriza/${props.estudiante.usuario}`,
        {
          estudiante: {
            estado: '1',
            historialAcademico: newHistorial,
          },
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${store.getObject('token')}`,
          },
        }
      );
      if (response.error) {
      } else {
        alert('Se han enviado las calificaciones con éxito.');
        window.location.reload(true);
      }
    }
  };

  const { classes, setEstudiante, estudiante } = props;
  if (ciclos.length < 1)
    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          paddingTop: '10%',
        }}>
        <Typography>
          Este usuario no tiene un historial académico aún.
        </Typography>
      </div>
    );

  return (
    <div>
      {loadingBoleta && <GlobalLoader />}
      <Grid container className={classes.root} justifyContent='center'>
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={4}
          style={{
            maxHeight: '46vh',
            overflow: 'scroll',
          }}>
          <Grid item xs={12}>
            <FormControl component='fieldset'>
              <FormLabel component='legend'>Grado</FormLabel>
              <RadioGroup
                aria-label='grado'
                name='grado'
                value={grade}
                onChange={handleChange}>
                {ciclos.map((ciclo, index) => (
                  <FormControlLabel
                    key={ciclo._id}
                    value={ciclo?._id}
                    control={
                      <Radio
                        color='primary'
                        onChange={(event) => {
                          if (event.target.checked) {
                            setSelected(ciclo._id);
                          }
                        }}
                      />
                    }
                    label={
                      <Typography
                        variant='body1'
                        style={{
                          color: ciclo?.autorizado
                            ? ciclo?.aprobado
                              ? '#4caf50'
                              : '#ff9800'
                            : '#f44336',
                        }}>
                        {getGrade(ciclo?.periodo)}
                      </Typography>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          {cicloSeleccionado?.materias.length > 0 ? (
            <React.Fragment>
              <Grid item container spacing={3} alignItems='stretch'>
                <Grid item xs={12} container justifyContent='space-between'>
                  <Typography variant='h5'>
                    {getGrade(cicloSeleccionado.periodo)}
                  </Typography>
                  <Box display='flex' gridGap='16px'>
                    <Button variant='outlined' onClick={handleClickEs}>
                      Descargar boleta ES
                    </Button>
                    <Menu
                      id='simple-menu'
                      anchorEl={anchorElEs}
                      keepMounted
                      open={Boolean(anchorElEs)}
                      onClose={handleCloseMenuEs}>
                      <MenuItem>
                        <Button
                          onClick={() => {
                            downloadBoleta({
                              grado: cicloSeleccionado.periodo,
                              idioma: 'es',
                              matricula: props.estudiante.usuario,
                              firmada: true,
                              setIsLoading: setLoadingBoleta,
                              setSnackBar: props.setSnackbar,
                            });
                            handleCloseMenuEs();
                          }}>
                          Firmada
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          onClick={() => {
                            downloadBoleta({
                              grado: cicloSeleccionado.periodo,
                              idioma: 'es',
                              matricula: props.estudiante.usuario,
                              firmada: false,
                              setIsLoading: setLoadingBoleta,
                              setSnackBar: props.setSnackbar,
                            });
                            handleCloseMenuEs();
                          }}>
                          Sin firma
                        </Button>
                      </MenuItem>
                    </Menu>
                    <Button variant='outlined' onClick={handleClick}>
                      Descargar boleta EN
                    </Button>
                    <Menu
                      id='simple-menu'
                      anchorEl={anchorEl}
                      keepMounted
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}>
                      <MenuItem>
                        <Button
                          onClick={() => {
                            downloadBoleta({
                              grado: cicloSeleccionado.periodo,
                              idioma: 'en',
                              matricula: props.estudiante.usuario,
                              firmada: true,
                              setIsLoading: setLoadingBoleta,
                              setSnackBar: props.setSnackbar,
                            });
                            handleCloseMenu();
                          }}>
                          Firmada
                        </Button>
                      </MenuItem>
                      <MenuItem>
                        <Button
                          onClick={() => {
                            downloadBoleta({
                              grado: cicloSeleccionado.periodo,
                              idioma: 'en',
                              matricula: props.estudiante.usuario,
                              firmada: false,
                              setIsLoading: setLoadingBoleta,
                              setSnackBar: props.setSnackbar,
                            });
                            handleCloseMenu();
                          }}>
                          Sin firma
                        </Button>
                      </MenuItem>
                    </Menu>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItem>
                      <Grid container>
                        <Grid item xs={5}>
                          <Typography>Inicio de Ciclo</Typography>
                        </Grid>
                        <Grid item xs={7}>
                          <DatePicker
                            name='periodoYear'
                            id='periodoYear'
                            value={fecha}
                            format='dd/MM/yyyy'
                            disabled={!props.editar}
                            onChange={(date) => {
                              setFecha(date.toISOString());
                              const cicloNuevaFecha = {
                                materias: cicloSeleccionado.materias,
                                autorizado: cicloSeleccionado.autorizado,
                                aprobado: cicloSeleccionado.aprobado,
                                fecha: date.toISOString(),
                                periodo: cicloSeleccionado.periodo,
                              };

                              setEstudiante({
                                ...estudiante,
                                historialAcademico: ciclos.map((c) =>
                                  c.periodo == cicloSeleccionado?.periodo
                                    ? cicloNuevaFecha
                                    : c
                                ),
                              });
                            }}
                          />
                        </Grid>
                      </Grid>
                    </ListItem>
                    {cicloSeleccionado?.materias?.map((materia, i) => (
                      <ListItem key={i}>
                        <Grid
                          container
                          alignItems='center'
                          justifyContent='center'
                          spacing={2}>
                          <Grid item xs={5}>
                            <Typography>{materia.nombre}</Typography>
                          </Grid>
                          <Grid item xs={5}>
                            <TextField
                              select
                              fullWidth
                              type='number'
                              disabled={!props.editar}
                              id='calificacion'
                              name='calificacion'
                              value={materia?.calificacion ?? ''}
                              className={classes.textField}
                              onChange={(event) => handleSelect(event, i)}>
                              <MenuItem value=''>
                                <em>N/A</em>
                              </MenuItem>
                              {[
                                '10',
                                '9',
                                '8',
                                '7',
                                '6',
                                '5',
                                '4',
                                '3',
                                '2',
                                '1',
                                '0',
                              ].map((option) => (
                                <MenuItem
                                  key={'option' + option}
                                  value={option}>
                                  {option}{' '}
                                </MenuItem>
                              ))}{' '}
                            </TextField>
                          </Grid>
                          <Grid item xs={2}>
                            <Tooltip title='Eliminar Materia' placement='left'>
                              <IconButton
                                onClick={deleteMateria(i)}
                                disabled={!props.editar}>
                                <DeleteIcon />
                              </IconButton>
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}{' '}
                  </List>
                </Grid>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12}>
                    {cicloSeleccionado?.materias.length > 0 ? (
                      cicloSeleccionado.autorizado ? null : (
                        <TextField
                          multiline
                          fullWidth
                          minRows={1}
                          disabled={!props.editar}
                          placeholder='Agrega un comentario'
                          id='comentario'
                          name='comentario'
                          variant='outlined'
                          value={comentario ? comentario : ''}
                          className={classes.textField}
                          onChange={(event) =>
                            setComentario(event.target.value)
                          }
                        />
                      )
                    ) : null}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Button
                      variant='contained'
                      color='primary'
                      fullWidth
                      onClick={() => setOpen(true)}
                      disabled={!props.editar}>
                      Añadir materia
                    </Button>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {cicloSeleccionado?.materias.length > 0 ? (
                      cicloSeleccionado.autorizado ? (
                        cicloSeleccionado.aprobado ? null : (
                          <Typography variant='body1'>
                            El usuario aún no aprueba la boleta
                          </Typography>
                        )
                      ) : (
                        <div>
                          <Button
                            variant='outlined'
                            color='primary'
                            onClick={authorize}
                            fullWidth>
                            Autorizar Boleta
                          </Button>
                        </div>
                      )
                    ) : null}
                  </Grid>
                </Grid>
              </Grid>
              <AgregarMateria
                open={open}
                handleClose={handleClose}
                saveMateria={saveMateria(cicloSeleccionado)}
              />
              <Grid item container spacing={3} alignItems='stretch'></Grid>
            </React.Fragment>
          ) : null}{' '}
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Estudiante);
