const fileToBase64 = (file, next) => {
    var reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = function () {
        return next(null, reader.result)
    };

    reader.onerror = function (error) {
        return next(error, null)
    };null};

export default fileToBase64;