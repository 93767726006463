//React
import React from 'react';

//HTTP Requests
import axios from 'axios';

//Components
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';

//Other Reqs
import * as store from '../../utils/localStore';
import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  root: {
    padding: '30px',
  },
  loadingFrame: {
    height: '60vh',
  },
  button: {
    margin: theme.spacing(1),
  },
  input: {
    display: 'none',
  },
  customIco: {
    width: 30,
  },
  title: {
    paddingTop: 25,
  },
});

const Inicio = (props) => {
  const [estudiantes, setEstudiantes] = React.useState([]);
  const [materias, setMaterias] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const { classes } = props;

  React.useEffect(() => {
    axios({
      method: 'GET',
      url: process.env.EDUCAZION_SERVER + 'alumnos/',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
        Authorization: 'Token ' + store.getObject('token'),
      },
    }).then((response) => {
      setEstudiantes(response.data);
      axios({
        method: 'GET',
        url: process.env.EDUCAZION_SERVER + 'materias/',
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + store.getObject('token'),
        },
      }).then((response1) => {
        setMaterias(response1.data);
        setLoading(false);
      });
    });
  }, [true]);

  return (
    <div className={classes.root}>
      <Typography variant='h5'>Inicio</Typography>
      <Typography variant='subtitle1'>
        Bienvenid@ al Control Escolar de Prepaenlinea.mx
      </Typography>
      <Divider />
      <br />
      <br />
      <br />
      <Typography variant='h6'>Información General</Typography>
      <Divider />
      <br />
      <br />
      {loading ? (
        <Grid
          container
          className={classes.loadingFrame}
          justifyContent='center'
          alignContent='center'
        >
          <Grid item>
            <CircularProgress color='secondary' />
          </Grid>
        </Grid>
      ) : (
        <Grid container justifyContent='center' alignContent='center'>
          <Grid item xs={12} sm={6}>
            <Typography variant='h6'>Estudiantes</Typography>
            <Typography>
              Total de Estudiantes: {estudiantes.length}
              <br />
              Último Estudiante :{' '}
              {estudiantes[estudiantes.length - 1].nombres +
                ' ' +
                estudiantes[estudiantes.length - 1].apellidoP +
                ' ' +
                estudiantes[estudiantes.length - 1].apellidoM}
              <br />
              Última Matrícula : {estudiantes[estudiantes.length - 1].usuario}
              <br />
              Fecha de Última Actualización :{' '}
              {estudiantes[estudiantes.length - 1].fecha_creacion}
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant='h6'>Materias</Typography>
            <Typography>
              Total de Materias: {materias.length}
              <br />
              Última Materia :{' '}
              {materias[materias.length - 1].clave +
                ' - ' +
                materias[materias.length - 1].nombre +
                ' - ' +
                materias[materias.length - 1].tutorAsignado}
              <br />
              Fecha de Última Actualización :{' '}
              {materias[materias.length - 1].fecha_creacion}
              <br />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>
        </Grid>
      )}
    </div>
  );
};

export default withStyles(styles)(Inicio);
