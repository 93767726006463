import React from 'react';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import Fotografia from '../Fotografia';
import PhotoIcon from '@material-ui/icons/Photo';
import DocsIcon from '@material-ui/icons/AssignmentReturned';

import classNames from 'classnames';

export const PhotoComponent = ({ estudiante, setSnackbar, editedStudent }) => {
  function getStatus(status) {
    status = status + '';
    switch (status) {
      case '0':
        return 'Cuenta Creada';
      case '1':
        return 'Cuenta Verificada';
      case '2':
        return 'Inscrito SIN Pago';
      case '3':
        return 'Inscrito';
      case '4':
        return 'En Proceso de Cierre';
      case '5':
        return 'Autorizado';
    }
  }

  function getColorStatus(status) {
    switch (status) {
      case '0':
        return '#ffc107';
      case '1':
        return '#8bc34a';
      case '2':
        return '#ff5722';
      case '3':
        return '#03a9f4';
      case '4':
        return '#f44336';
      case '5':
        return '#9c27b0';
    }
  }

  function downloadImage(imageSrc) {
    const link = document.createElement('a');
    link.href = imageSrc;
    link.download = `foto_${editedStudent.usuario}.jpg`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  function downloadDoc(docSrc) {
    const link = document.createElement('a');
    link.href = docSrc;
    link.download = `acta_${editedStudent.usuario}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Box>
      <Fotografia setSnackbar={setSnackbar} estudiante={estudiante} />
      <Tooltip
        title={editedStudent.tieneBeca == 'S' ? 'Es Becado' : 'NO es Becado'}>
        <div
          className={classNames('barra', {
            gray: editedStudent.tieneBeca != 'S',
            blue: editedStudent.tieneBeca == 'S',
          })}
        />
      </Tooltip>
      <Tooltip title={getStatus(editedStudent.estado) ?? ''}>
        <div
          className={classNames('barra')}
          style={{
            backgroundColor: getColorStatus(editedStudent.estado),
          }}
        />
      </Tooltip>
      {editedStudent.boletaImpresa && (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
          }}>
          <Typography variant='caption' align='center'>
            <b>El estudiante está solicitando una boleta.</b>
          </Typography>
        </div>
      )}
      <Box display='flex' gridGap={10} justifyContent='center'>
        <Tooltip title='Descargar Foto'>
          <IconButton
            onClick={() =>
              downloadImage(`${process.env.BUCKET_S3}/${editedStudent.foto}`)
            }>
            <PhotoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Descargar Acta'>
          <IconButton
            disabled={!editedStudent.actaNacimiento}
            onClick={() =>
              downloadDoc(`${process.env.BUCKET_S3}/${editedStudent.actaNacimiento}`)
            }>
            <DocsIcon />
          </IconButton>
        </Tooltip>
      </Box>
    </Box>
  );
};
