//React
import React from 'react';

//Axios for HTTP Requests
import axios from 'axios';

//Components
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import RaisedButton from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Slide from '@material-ui/core/Slide';
import Fade from '@material-ui/core/Fade';
import LinearProgress from '@material-ui/core/LinearProgress';

//ICONS
import AdminIcon from '../../images/logo.png';
import Person from '@material-ui/icons/Person';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Alert from '../../componentes/Alert';

//Other Reqs
import * as store from '../../utils/localStore';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: '100vh',
    width: '100vw',
    backgroundColor: 'white',
  },
  margin: {
    margin: theme.spacing(1),
  },
  card: {
    minWidth: 275,
    width: 600,
    minHeight: 200,
    backgroundColor: 'rgb(230, 230, 230)',
  },
  title: {
    fontSize: 14,
  },
  textField: {
    flexBasis: 200,
  },
  logo: {
    width: '120px',
  },
});

const Login = (props) => {
  const [usuario, setUsuario] = React.useState('');
  const [password, setPassword] = React.useState('');
  const [showPassword, setShowPassword] = React.useState(false);
  const [started, setStarted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [alert, setAlert] = React.useState({
    open: false,
    title: '',
    text: '',
  });

  function closeAlert() {
    setAlert({ open: false, title: '', text: '' });
  }

  function handleFormSubmit(event) {
    event.preventDefault();
    setLoading(true);
    axios({
      method: 'POST',
      url: process.env.EDUCAZION_SERVER + 'usuarios/login',
      responseType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      data: {
        usuario: {
          email: usuario,
          password: password,
        },
      },
    })
      .then((response) => {
        setLoading(false);
        if (response.data.usuario == undefined) {
          setAlert({ open: true, title: 'Advertencia', text: response.data });
        } else {
          store.setObjects('session', { usuario: response.data.user });
          store.setObject('token', response.data.usuario.token);
          props.history.push('/main');
        }
      })
      .catch(() => {
        setLoading(false);
        setAlert({
          open: true,
          title: 'Error',
          text: 'Ocurrió un error, por favor intente de nuevo.',
        });
      });
  }

  React.useEffect(() => {
    setStarted(true);
    if (store.getObject('token')) {
      setLoading(true);
      axios({
        method: 'GET',
        url: process.env.EDUCAZION_SERVER + 'usuarios/current',
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + store.getObject('token'),
        },
      }).then((response) => {
        setLoading(false);
        // store.setObjects("session", response.data)
        props.history.push('/main');
      });
    }
  }, []);

  const { classes } = props;
  return (
    <div>
      <LinearProgress className='linear' hidden={!loading} color='secondary' />
      <Alert
        title={alert.title}
        open={alert.open}
        text={alert.text}
        closeAlert={closeAlert}
        success={closeAlert}
      />
      <Grid
        className={classes.root}
        container
        direction='column'
        justifyContent='center'
        alignItems='center'>
        <Grid item>
          <Fade in={started}>
            <Grid
              container
              direction='column'
              justifyContent='center'
              alignItems='center'>
              <Grid item>
                <img className={classes.logo} src={AdminIcon} />
              </Grid>
              <Grid item>
                <Typography variant='h5' color='textSecondary'>
                  Control Escolar
                </Typography>
              </Grid>
            </Grid>
          </Fade>
        </Grid>
        <br />
        <br />
        <Grid item>
          <Slide direction='up' in={started} mountOnEnter unmountOnExit>
            <Card className={classes.card}>
              <form id='loginForm' onSubmit={handleFormSubmit}>
                <CardContent>
                  <br />
                  <FormControl
                    fullWidth
                    className={classNames(classes.margin, classes.textField)}>
                    <InputLabel htmlFor='user'>Usuario</InputLabel>
                    <Input
                      id='user'
                      autoFocus={true}
                      type='text'
                      required={true}
                      value={usuario}
                      onChange={(event) => setUsuario(event.target.value)}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton aria-label='Usuario'>
                            <Person />
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <FormControl
                    fullWidth
                    className={classNames(classes.margin, classes.textField)}>
                    <InputLabel htmlFor='adornment-password'>
                      Password
                    </InputLabel>
                    <Input
                      id='adornment-password'
                      required={true}
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                      endAdornment={
                        <InputAdornment position='end'>
                          <IconButton
                            aria-label='Cambia la visibilidad del password'
                            onClick={() => setShowPassword(!showPassword)}>
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  <Typography
                    className={classes.title}
                    color='textSecondary'
                    gutterBottom>
                    Si ha olvidado su contraseña es necesario que hable con su
                    administrador.
                  </Typography>
                </CardContent>
                <br />
                <CardActions>
                  <RaisedButton type='submit'>Iniciar Sesión</RaisedButton>
                </CardActions>
              </form>
            </Card>
          </Slide>
        </Grid>
      </Grid>
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Login);
