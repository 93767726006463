import React from 'react';
import { Box, Tab, Tabs } from '@material-ui/core';
import InformacionAcademica from '../InformacionAcademica';
import HistorialAcademico from '../HistorialAcademico';
import EstadoCuenta from '../EstadoCuenta';

export const AcademicInfo = ({
  handleText,
  editedStudent,
  setEditedStudent,
  setSnackbar,
  editar,
  fechaEntrada,
  historialAcademico,
  handleSelect,
  handleFechaRoyal,
  historialPagos,
  setHistorialPagos,
  reload,
  setReload,
}) => {
  const [value, setValue] = React.useState(0);
  function handleChange(event, newValue) {
    setValue(newValue);
  }

  function a11yProps(index) {
    return {
      id: `full-width-tab-${index}`,
      'aria-controls': `full-width-tabpanel-${index}`,
    };
  }
  return (
    <Box>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor='primary'
        textColor='primary'
        variant='fullWidth'
        aria-label='full width tabs example'>
        <Tab label='Información Académica' {...a11yProps(0)} />
        <Tab label='Historial Académico' {...a11yProps(1)} />
        <Tab label='Historial de Pagos' {...a11yProps(2)} />{' '}
      </Tabs>

      <Box>
        {value === 0 && (
          <InformacionAcademica
            setReload={setReload}
            reload={reload}
            handleText={handleText}
            estudiante={editedStudent}
            setEstudiante={setEditedStudent}
            setSnackbar={setSnackbar}
            editar={editar}
            fechaEntrada={fechaEntrada}
            historialAcademico={historialAcademico}
            handleSelect={handleSelect}
            handleFecha={handleFechaRoyal}
          />
        )}
        {value === 1 && (
          <HistorialAcademico
            handleText={handleText}
            estudiante={editedStudent}
            setEstudiante={setEditedStudent}
            editar={editar}
            setSnackbar={setSnackbar}
          />
        )}
        {value === 2 && (
          <EstadoCuenta
            estudiante={editedStudent}
            setEstudiante={setEditedStudent}
            editar={editar}
            handleText={handleText}
            historialPagos={historialPagos}
            setHistorialPagos={setHistorialPagos}
            setSnackbar={setSnackbar}
            handleSelect={handleSelect}
          />
        )}
      </Box>
    </Box>
  );
};
