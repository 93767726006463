// React
import React, { useEffect } from 'react';

// Axios for HTTP Requests
import axios from 'axios';

// Components
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import PacmanLoader from 'react-spinners/PacmanLoader';

import Estudiante from '../../componentes/estudiantes/Estudiante';
import BarraBusqueda from '../../componentes/estudiantes/BarraBusqueda';

// Other Reqs
import * as store from '../../utils/localStore';
import { Typography } from '@material-ui/core';
import BarraHerramientas from '../../componentes/estudiantes/BarraHerramientas';
import { useSaveStudent } from '../../componentes/estudiantes/useSaveStudent';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    height: 'calc(100vh - 72px)',
  },
  margin: {
    margin: theme.spacing(1),
  },
  card: {
    minWidth: 275,
    width: 600,
    minHeight: 200,
    backgroundColor: 'rgb(230, 230, 230)',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  textField: {
    flexBasis: 200,
    // width: '95%'
  },
  logo: {
    width: '120px',
  },
});

const Estudiantes = (props) => {
  const [estudiantes, setEstudiantes] = React.useState([]);
  const [estudianteSeleccionado, setEstudianteSeleccionado] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [reload, setReload] = React.useState(true);
  const [rol, setRol] = React.useState('visitante');
  const [editar, setEditar] = React.useState(false);
  const [reloadEstudiante, setReloadEstudiante] = React.useState(true);
  const [editedStudent, setEditedStudent] = React.useState({});

  const [searchFilter, setSearchFilter] = React.useState('');
  const [paramFilter, setParamFilter] = React.useState('nombre');
  const [isSearchReady, setIsSearchReady] = React.useState(false);
  const [snackbar, setSnackbar] = React.useState({
    open: false,
    variant: '',
    text: '',
  });

  const { saveEstudiante } = useSaveStudent({
    estudiante: editedStudent,
    setLoading,
    setReload,
    reload,
    setSnackbar,
  });

  const { classes } = props;

  useEffect(() => {
    setRol(store.getObjects('session').usuario.rol);
  }, []);

  useEffect(() => {
    if (searchFilter !== '' && paramFilter !== '' && isSearchReady) {
      setLoading(true);
      axios({
        method: 'GET',
        url: `${process.env.EDUCAZION_SERVER}estudiantes/`,
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Token ' + store.getObject('token'),
        },
        params: { [paramFilter]: searchFilter },
      })
        .then((res) => {
          setEstudiantes(res.data);
          setIsSearchReady(false);
          setLoading(false);
        })
        .catch((err) => {
          console.error(err);
          setLoading(false);
        });
    }
  }, [searchFilter, paramFilter, isSearchReady]);

  return (
    <div>
      <LinearProgress className='linear' hidden={!loading} color='secondary' />
      <BarraBusqueda
        totalEstudiantes={estudiantes?.length}
        estudianteIndex={estudianteSeleccionado}
        setEstudianteIndex={setEstudianteSeleccionado}
        setEstudiantes={setEstudiantes}
        estudiantes={estudiantes}
        searchFilter={searchFilter}
        setSearchFilter={setSearchFilter}
        paramFilter={paramFilter}
        setParamFilter={setParamFilter}
        setIsSearchReady={setIsSearchReady}
      />
      {loading && (
        <Grid
          className={classes.root}
          container
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <PacmanLoader color='#36d7b7' size={64} margin={4} />
          </Grid>
        </Grid>
      )}
      {estudiantes.length > 0 && !loading && (
        <Estudiante
          setEstudianteIndex={setEstudianteSeleccionado}
          estudiante={estudiantes[estudianteSeleccionado]}
          reload={reload}
          setReload={setReload}
          estudiantes={estudiantes}
          loading={loading}
          setLoading={setLoading}
          rol={rol}
          editar={editar}
          setEditar={setEditar}
          setSnackbar={setSnackbar}
          snackbar={snackbar}
          reloadEstudiante={reloadEstudiante}
          setEditedStudent={setEditedStudent}
          editedStudent={editedStudent}
        />
      )}
      {estudiantes.length === 0 && !loading && (
        <Grid
          className={classes.root}
          container
          justifyContent='center'
          alignItems='center'>
          <Grid item>
            <Typography variant='h6' color='primary'>
              Ingresa un nombre o matrícula para realizar la búsqueda
            </Typography>
          </Grid>
        </Grid>
      )}
      {rol == 'administrador' && (
        <div style={{ textAlign: 'center' }}>
          <BarraHerramientas
            editar={editar}
            setEditar={setEditar}
            setReload={props.setReload}
            reload={props.reload}
            restaurar={() => setReloadEstudiante(!reloadEstudiante)}
            saveEstudiante={saveEstudiante}
            setSnackbar={setSnackbar}
          />
        </div>
      )}
    </div>
  );
};

export default withStyles(styles, { withTheme: true })(Estudiantes);
