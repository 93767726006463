import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import Print from '@material-ui/icons/Print';
import Tooltip from '@material-ui/core/Tooltip';

//Axios for HTTP Requests
import axios from 'axios';
import * as store from '../../utils/localStore';

import download from 'downloadjs';

export default function SimpleMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const downloadConstancia = async (idioma) => {
    handleClose();
    try {
      props.setLoading(true);
      const response = await axios.get(
        `${process.env.EDUCAZION_SERVER}estudiantes/${props.estudiante.usuario}/constancia`,
        {
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${store.getObject('token')}`,
          },
          params: {
            idioma: idioma,
          },
        }
      );
      download(
        `${process.env.EDUCAZION_SERVER}estudiantes/download/file?path=${response.data}`
      );
    } catch (error) {
      alert('Error al generar la constancia');
    } finally {
      props.setLoading(false);
    }
  };

  const downloadCredencial = async () => {
    handleClose();
    try {
      props.setLoading(true);
      const response = await axios.get(
        `${process.env.EDUCAZION_SERVER}estudiantes/${props.estudiante.usuario}/credencial`,
        {
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Token ${store.getObject('token')}`,
          },
          params: {
            idioma: 'en',
          },
        }
      );
      const hiddenElement = document.createElement('a');
      hiddenElement.href = `${process.env.EDUCAZION_SERVER}estudiantes/download/file?path=${response.data}`;
      hiddenElement.target = '_blank';
      hiddenElement.download = 'credencial_educazion.pdf';
      hiddenElement.click();
    } catch (error) {
      alert('Error al generar la credencial');
    } finally {
      props.setLoading(false);
    }
  };

  return (
    <div>
      <Tooltip title='Impresiones'>
        <IconButton aria-label='delete' size='medium' onClick={handleClick}>
          <Print fontSize='inherit' />
        </IconButton>
      </Tooltip>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}>
        <MenuItem>
          <Grid
            container
            alignItems='center'
            spacing={2}
            style={{ minWidth: '300px' }}>
            <Grid
              item
              xs={12}
              onClick={() => {
                downloadConstancia('es');
              }}>
              Constancia de Estudios [ES]
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Grid
            container
            alignItems='center'
            spacing={2}
            style={{ minWidth: '300px' }}>
            <Grid
              item
              xs={12}
              onClick={() => {
                downloadConstancia('en');
              }}>
              Constancia de Estudios [EN]
            </Grid>
          </Grid>
        </MenuItem>
        <MenuItem>
          <Grid
            container
            alignItems='center'
            spacing={2}
            style={{ minWidth: '300px' }}>
            <Grid
              item
              xs={12}
              onClick={() => {
                downloadCredencial();
              }}>
              Credencial
            </Grid>
          </Grid>
        </MenuItem>
      </Menu>
    </div>
  );
}
