//React
import React from 'react';

//Components
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Slider from '@material-ui/core/Slider';

//Icons
import Back from '@material-ui/icons/ChevronLeft';
import Forward from '@material-ui/icons/ChevronRight';
import First from '@material-ui/icons/FirstPage';
import Last from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';

//Other Reqs
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import * as store from '../../utils/localStore';

const styles = (theme) => ({
  root: {
    height: '64px',
    width: '100%',
    backgroundColor: '#80cbc4',
    boxShadow:
      '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
  },
  form: {
    display: 'contents !important',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
    width: '100%',
    margin: 0,
  },
});

const CustomSlider = withStyles({
  root: {
    color: '#ff5722',
    //   height: 2,
    //   padding: '15px 0',
  },
  track: {
    height: 2,
  },
  rail: {
    height: 2,
    opacity: 0.5,
    backgroundColor: '#ff7043',
  },
  mark: {
    backgroundColor: '#ff7043',
    height: 8,
    width: 1,
    marginTop: -3,
  },
  markActive: {
    opacity: 1,
    backgroundColor: 'currentColor',
  },
})(Slider);

const BarraBusqueda = (props) => {
  const [rol, setRol] = React.useState('visitante');
  const {
    searchFilter,
    setSearchFilter,
    paramFilter,
    setParamFilter,
    setIsSearchReady,
  } = props;

  function buscar() {
    setIsSearchReady(true);
  }

  React.useEffect(() => {
    setRol(store.getObjects('session').usuario.rol);
  }, []);

  const marks = [
    {
      value: 1,
      // label: 1
    },
    {
      value: Math.round((props.totalEstudiantes / 4) * 1),
      // label: Math.round((props.totalEstudiantes / 4) * 1)
    },
    {
      value: Math.round((props.totalEstudiantes / 4) * 2),
      // label: Math.round((props.totalEstudiantes / 4) * 2)
    },
    {
      value: Math.round((props.totalEstudiantes / 4) * 3),
      // label: Math.round((props.totalEstudiantes / 4) * 3)
    },
    {
      value: Math.round((props.totalEstudiantes / 4) * 4),
      // label: Math.round((props.totalEstudiantes / 4) * 4)
    },
  ];

  const { classes } = props;

  return (
    <Grid
      className={classes.root}
      container
      justifyContent='center'
      alignItems='center'>
      {props.estudiantes.length > 0 && (
        <>
          <Grid item xs={3}>
            <Grid container alignItems='center'>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Tooltip title='Ir al primero'>
                  <span>
                    <IconButton
                      disabled={props.estudianteIndex <= 0}
                      aria-label='delete'
                      className={classes.margin}
                      size='small'
                      onClick={() => props.setEstudianteIndex(0)}>
                      <First fontSize='inherit' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Tooltip title='Atrás'>
                  <span>
                    <IconButton
                      disabled={props.estudianteIndex <= 0}
                      aria-label='delete'
                      className={classes.margin}
                      size='small'
                      onClick={() =>
                        props.setEstudianteIndex(
                          props.estudianteIndex == 0
                            ? 0
                            : props.estudianteIndex - 1
                        )
                      }>
                      <Back fontSize='inherit' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Tooltip title='Adelante'>
                  <span>
                    <IconButton
                      aria-label='delete'
                      className={classes.margin}
                      size='small'
                      disabled={
                        props.totalEstudiantes == 0 ||
                        props.estudianteIndex == props.totalEstudiantes - 1
                      }
                      onClick={() =>
                        props.setEstudianteIndex(
                          props.estudianteIndex == props.totalEstudiantes - 1
                            ? props.totalEstudiantes - 1
                            : props.estudianteIndex + 1
                        )
                      }>
                      <Forward fontSize='inherit' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={3} style={{ textAlign: 'center' }}>
                <Tooltip title='Ir al último'>
                  <span>
                    <IconButton
                      aria-label='delete'
                      className={classes.margin}
                      size='small'
                      disabled={
                        props.totalEstudiantes == 0 ||
                        props.estudianteIndex == props.totalEstudiantes - 1
                      }
                      onClick={() =>
                        props.setEstudianteIndex(props.totalEstudiantes - 1)
                      }>
                      <Last fontSize='inherit' />
                    </IconButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <CustomSlider
                defaultValue={props.estudianteIndex + 1}
                value={props.estudianteIndex + 1}
                className='barraBusqueda'
                onChange={(_event, value) =>
                  props.setEstudianteIndex(value - 1)
                }
                disabled={props.totalEstudiantes === 0}
                aria-labelledby='discrete-slider-custom'
                step={1}
                min={1}
                max={props.totalEstudiantes}
                valueLabelDisplay='auto'
                marks={marks}
              />
            </Grid>
          </Grid>
          <Grid item xs={2} style={{ textAlign: 'center' }}>
            {props.totalEstudiantes === 0 ? 0 : props.estudianteIndex + 1} de{' '}
            {props.totalEstudiantes}
          </Grid>
        </>
      )}
      <form onSubmit={buscar} className={classes.form}>
        <Grid item xs={rol == 'administrador' ? 3 : 4}>
          <TextField
            id='filled-simple-start-adornment'
            value={searchFilter}
            className={classNames(classes.margin, classes.textField)}
            onChange={(event) => setSearchFilter(event.target.value)}
            InputProps={{
              startAdornment: (
                <InputAdornment position='start'>
                  <Search />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            select
            value={paramFilter}
            className={classNames(classes.margin, classes.textField)}
            onChange={(event) => setParamFilter(event.target.value)}>
            <MenuItem value='nombre'>Nombre Completo</MenuItem>
            <MenuItem value='matricula'>Matrícula</MenuItem>
            <MenuItem value='email'>Correo Electrónico</MenuItem>
          </TextField>
        </Grid>
        <button id='busquedaglobal' type='submit' hidden />
      </form>
    </Grid>
  );
};

export default withStyles(styles, { withTheme: true })(BarraBusqueda);
