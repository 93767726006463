import axios from 'axios';
import * as store from './localStore';

export const downloadBoleta = async ({
  grado,
  idioma,
  matricula,
  firmada,
  setIsLoading,
  setSnackBar,
}) => {
  setIsLoading(true);
  try {
    const response = await axios.get(
      `${process.env.EDUCAZION_SERVER}estudiantes/${matricula}/boleta`,
      {
        responseType: 'json',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Token ${store.getObject('token')}`,
        },
        params: {
          idioma,
          grado,
          firma: firmada,
        },
      }
    );
    setIsLoading(false);
    setSnackBar({
      open: true,
      variant: 'success',
      text: 'Boleta generada correctamente',
    });
    const hiddenElement = document.createElement('a');
    hiddenElement.href = `${process.env.EDUCAZION_SERVER}estudiantes/download/file?path=${response.data}`;
    hiddenElement.target = '_blank';
    hiddenElement.download = 'constancia_educazion.pdf';
    hiddenElement.click();
  } catch (error) {
    setSnackBar({
      open: true,
      variant: 'error',
      text: 'Error al generar la boleta',
    });
    setIsLoading(false);
    console.log('Error', error.response?.data?.message || error);
  }
};
