//React
import React from 'react';

//Components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';

//Other Reqs
import { withStyles } from '@material-ui/core/styles';

import materias from '../../utils/materias';

const styles = (theme) => ({
  root: {
    padding: '30px',
    overflow: 'auto',
    height: 'auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  textField: {
    flexBasis: 200,
  },
});

const grade = {
  PRE01: materias[0].materias,
  PRE02: materias[1].materias,
  PRE03: materias[2].materias,
  PRI01: materias[3].materias,
  PRI02: materias[4].materias,
  PRI03: materias[5].materias,
  PRI04: materias[6].materias,
  PRI05: materias[7].materias,
  PRI06: materias[8].materias,
  SEC07: materias[9].materias,
  SEC08: materias[10].materias,
  SEC09: materias[11].materias,
};

const getGrade = (nivel) => grade[nivel] || [];

const gradeName = {
  PRE01: '1er Grado de Preescolar',
  PRE02: '2do Grado de Preescolar',
  PRE03: '3er Grado de Preescolar',
  PRI01: '1er Grado de Primaria (1ro)',
  PRI02: '2do Grado de Primaria (2do)',
  PRI03: '3er Grado de Primaria (3ro)',
  PRI04: '4to Grado de Primaria (4to)',
  PRI05: '5to Grado de Primaria (5to)',
  PRI06: '6to Grado de Primaria (6to)',
  SEC07: '1er Grado de Secundaria (7mo)',
  SEC08: '2do Grado de Secundaria (8vo)',
  SEC09: '3er Grado de Secundaria (9no)',
};

const getGradeName = (nivel) =>
  gradeName[nivel] || 'Error en el nivel, actualizar grado';

const Estudiante = (props) => {
  const [estudiante, setEstudiante] = React.useState({});
  const [fechaInscripcion, setFechaInscripcion] = React.useState('0001-01-01');
  const [royalFecha, setRoyalFecha] = React.useState('0001-01-01');
  const [materiasActuales, setMateriasActualers] = React.useState([]);

  const { fechaEntrada } = props;

  React.useEffect(() => {
    if (props.estudiante.nivelActual)
      if (props.estudiante.nivelActual != '') {
        const fechaN = new Date(props.estudiante.fechaInscripcion);
        const dia =
          fechaN.getDate() < 10 ? '0' + fechaN.getDate() : fechaN.getDate();
        const mes =
          fechaN.getMonth() + 1 < 10
            ? '0' + (fechaN.getMonth() + 1)
            : fechaN.getMonth() + 1;
        const fechaR = new Date(props.estudiante.royalFecha);
        const diaR = fechaR.getDate();
        const mesR =
          fechaR.getMonth() + 1 < 10
            ? '0' + (fechaR.getMonth() + 1)
            : fechaR.getMonth() + 1;

        setMateriasActualers(getGrade(props.estudiante.nivelActual));

        setFechaInscripcion(fechaN.getFullYear() + '-' + mes + '-' + dia);
        setRoyalFecha(fechaR.getFullYear() + '-' + mesR + '-' + diaR);
        setEstudiante(props.estudiante);
      }
  }, [props.estudiante]);

  const { classes } = props;
  if (props.estudiante.nivelActual)
    if (props.estudiante.nivelActual != '')
      return (
        <div>
          <Grid container className={classes.root} justifyContent='center'>
            <Grid item xs={12} sm={7}>
              <Grid container spacing={1}>
                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <TextField
                    disabled={!props.editar}
                    id='fechaInscripcion'
                    fullWidth
                    label='Fecha de Inscripcion'
                    type='date'
                    value={fechaEntrada}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={4}>
                  <TextField
                    fullWidth
                    disabled={!props.editar}
                    id='estado'
                    label='Estatus'
                    value={props.estudiante.estado ?? ''}
                    className={classes.textField}
                    onChange={props.handleText}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <TextField
                    select
                    fullWidth
                    disabled={!props.editar}
                    id='actaEntregada'
                    name='actaEntregada'
                    label='¿Entregó Acta?'
                    value={props.estudiante.actaEntregada ?? false}
                    className={classes.textField}
                    onChange={(event) => props.handleSelect(event)}>
                    <MenuItem value={true}>S</MenuItem>
                    <MenuItem value={false}>N</MenuItem>
                  </TextField>
                </Grid>

                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <TextField
                    fullWidth
                    disabled={!props.editar}
                    id='royalId'
                    label='ID Royal Hollow'
                    value={props.estudiante.royalId ?? ''}
                    className={classes.textField}
                    onChange={props.handleText}
                  />
                </Grid>
                <Grid item xs={6} sm={6} md={4} lg={4}>
                  <TextField
                    disabled={!props.editar}
                    id='royalFecha'
                    fullWidth
                    label='Fecha de Royal Hollow'
                    type='date'
                    value={royalFecha}
                    onChange={props.handleFecha}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Grid container justifyContent='center' alignItems='center'>
                <Grid item xs={12} sm={12} md={11} lg={10}>
                  <Typography variant='h5'>
                    {getGradeName(props.estudiante.nivelActual)}
                  </Typography>
                  <Typography variant='h6'>Materias Cursando</Typography>
                </Grid>
                {materiasActuales.map((materia) => (
                  <Grid
                    key={materia.clave}
                    item
                    xs={12}
                    sm={12}
                    md={11}
                    lg={10}>
                    <Typography>
                      <b>
                        {materia.clave} - {materia.nombre}
                      </b>
                    </Typography>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </div>
      );
    else
      return (
        <div
          style={{
            width: '100%',
            textAlign: 'center',
            paddingTop: '10%',
            height: '20vh',
          }}>
          <Typography>
            Este usuario no está cursando ninguna currícula.
          </Typography>
        </div>
      );
  else
    return (
      <div
        style={{
          width: '100%',
          textAlign: 'center',
          paddingTop: '10%',
          height: '20vh',
        }}>
        <Typography>
          Este usuario no está cursando ninguna currícula.
        </Typography>
      </div>
    );
};

export default withStyles(styles, { withTheme: true })(Estudiante);
