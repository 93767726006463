//React
import React from 'react';

//Axios for HTTP Requests
import axios from 'axios';

//Components
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';

//Icons
import Unarchive from '@material-ui/icons/Unarchive';
import Delete from '@material-ui/icons/Clear';
import Add from '@material-ui/icons/Add';

//Other Reqs
import { withStyles } from '@material-ui/core/styles';
const { fromJS } = require('immutable');
import * as store from '../../utils/localStore';
import fileToBase64 from '../../utils/fileToBase64';
import { generateAlumnReference } from '../../utils/configuracion';

const styles = (theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  cancelButton: {
    position: 'absolute',
    margin: '-3px',
    color: 'red',
  },
});

const emptyNuevo = {
  nombres: '',
  apellidoP: '',
  apellidoM: '',
  usuario: '',
  password: '',
  foto: '',
  centroEducativo: '',
  observaciones: '',
  marcarReg: '',
  promedioGeneral: '',
  adeudoAnterior: '',
  cicloEscolarInicial: '',
  consecutivo: 0,
  estatus: '',
  direccion: '',
  pais: '',
  sexo: '',
  grado: 0,
  constanciaDiploma: '',
  tipoDocumentoFinal: '',
  telefono: '',
  fechaInscripcion: null,
  fechaTermino: null,
  emailPersonal: '',
  emailInstitucional: '',
  fechaNacimiento: null,
  actaEntregada: false,
  promGPA: '',
  reinscrito: false,
  tieneBeca: '',
  tieneAdeudos: '',
  totalMaterias: 0,
  totalMateriasCursadas: 0,
  totalMateriasObligadas: 0,
  historialAcademico: [],
  materiasActuales: [],
  tutor: {
    nombre: '',
    email: '',
    telefono: '',
  },
  historialCrediticio: {
    adeudoInicial: '$0.00',
    abonos: [],
    cargos: [],
    abonoTotal: '$0.00',
    cargoTotal: '$0.00',
    total: '$0.00',
    adeudoPendiente: '$0.00',
  },
  factura: {
    requiereFactura: false,
    datosFacturacion: {},
    facturas: [],
  },
  royalId: '',
  royalFecha: '',
};

const e1 = fromJS(emptyNuevo);

function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [nuevoEstudiante, setNuevoEstudiante] = React.useState(e1.toJS());
  const [image, setImage] = React.useState(null);

  function handleClose() {
    setOpen(false);
  }

  function handleImage(event) {
    let file = event.target.files[0];
    if (
      ['image/jpeg', 'image/jpg', 'image/png', 'image/bmp'].indexOf(
        file.type
      ) === -1
    ) {
      props.setSnackbar({
        open: true,
        variant: 'error',
        text: 'Los formatos soportados para este archivo son: JPEG, JPG, PNG, BMP.',
      });
    } else if (file.size > 2000000) {
      props.setSnackbar({
        open: true,
        variant: 'error',
        text:
          'El peso de la fotografía no puede ser mayor a 2mb. (La suya pesa ' +
          file.size / 1000000 +
          'mb)',
      });
    } else {
      setImage({
        image: URL.createObjectURL(file),
        imageRaw: file,
      });
    }
  }

  function handleSave() {
    nuevoEstudiante.usuario = generateAlumnReference('32', '999', '2019', '3');
    fileToBase64(image.imageRaw, (err, fotob64) => {
      if (err) alert(err);
      else {
        let fotografia = {
          blob: fotob64.split(',')[1],
          type: fotob64.split(',')[0] + ',',
          nombre: image.imageRaw.name,
        };
        axios({
          method: 'POST',
          url: process.env.EDUCAZION_SERVER + 'alumnos/',
          responseType: 'json',
          data: {
            alumno: nuevoEstudiante,
            fotografia: JSON.stringify(fotografia),
          },
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + store.getObject('token'),
          },
        })
          .then(() => {
            props.setReload(!props.reload);
            props.setSnackbar({
              open: true,
              variant: 'success',
              text: 'Estudiante agregado con éxito.',
            });
            setOpen(false);
          })
          .catch((error) => {
            props.setSnackbar({
              open: true,
              variant: 'error',
              text: 'Ha habido un error.',
            });
          });
      }
    });
  }

  function handleText(event) {
    let e = fromJS(nuevoEstudiante);
    let ef = e.set(event.target.id, event.target.value);
    setNuevoEstudiante(ef.toJS());
  }

  const { classes } = props;

  return (
    <div>
      <Tooltip title='Nuevo Estudiante'>
        <IconButton
          aria-label='delete'
          size='medium'
          className={classes.margin}
          onClick={() => setOpen(true)}>
          <Add fontSize='inherit' />
        </IconButton>
      </Tooltip>
      <Dialog
        fullWidth
        maxWidth='lg'
        open={open}
        onClose={handleClose}
        aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>Nuevo Estudiante</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>Fotografía</Typography>
            </Grid>
            {image == null ? (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Tooltip title='Subir Archivo'>
                  <IconButton
                    aria-label='delete'
                    size='medium'
                    className={classes.margin}
                    onClick={() => document.getElementById('imgFile').click()}>
                    <Unarchive fontSize='inherit' />
                  </IconButton>
                </Tooltip>
                <input id='imgFile' type='file' onChange={handleImage} hidden />
              </Grid>
            ) : (
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                <Tooltip title='Eliminar Archivo'>
                  <IconButton
                    aria-label='delete'
                    size='medium'
                    className={classes.cancelButton}
                    onClick={() => setImage(null)}>
                    <Delete fontSize='inherit' />
                  </IconButton>
                </Tooltip>
                <img
                  src={image.image}
                  style={{ minWidth: '150px', maxHeight: '175px' }}
                />
              </Grid>
            )}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant='subtitle1'>Información Mínima</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                margin='dense'
                id='nombres'
                label='Nombre(s)'
                type='text'
                value={nuevoEstudiante.nombres ? nuevoEstudiante.nombres : ''}
                onChange={handleText}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                margin='dense'
                id='apellidoP'
                label='Apellido Paterno'
                type='text'
                value={
                  nuevoEstudiante.apellidoP ? nuevoEstudiante.apellidoP : ''
                }
                onChange={handleText}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                margin='dense'
                id='apellidoM'
                label='Apellido Materno'
                type='text'
                value={
                  nuevoEstudiante.apellidoM ? nuevoEstudiante.apellidoM : ''
                }
                onChange={handleText}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                margin='dense'
                id='emailPersonal'
                label='Email Personal'
                type='email'
                value={
                  nuevoEstudiante.emailPersonal
                    ? nuevoEstudiante.emailPersonal
                    : ''
                }
                onChange={handleText}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                margin='dense'
                id='telefono'
                label='Teléfono'
                type='text'
                value={nuevoEstudiante.telefono ? nuevoEstudiante.telefono : ''}
                onChange={handleText}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                autoFocus
                margin='dense'
                id='pais'
                label='Pais'
                type='text'
                value={nuevoEstudiante.pais ? nuevoEstudiante.pais : ''}
                onChange={handleText}
                fullWidth
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color='primary'>
            Cancelar
          </Button>
          <Button onClick={handleSave} color='primary'>
            Agregar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default withStyles(styles, { withTheme: true })(FormDialog);
