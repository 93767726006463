//React
import React from 'react';

//Components
import inicio from '../../subvistas/inicio/inicio';
import MenuBar from '../../componentes/main/MenuBar';
import estudiantes from '../../subvistas/estudiantes/estudiantes';

//Other Reqs
import * as store from '../../utils/localStore';
import axios from 'axios';
import { Redirect, Switch, Route } from 'react-router-dom';

import { jwtDecode } from 'jwt-decode';

const Main = (props) => {
  const { match } = props;

  React.useEffect(() => {
    const token = store.getObject('token');
    const decoded = jwtDecode(token);
    const expiresIn = new Date(decoded.exp * 1000).getTime();
    const expired = expiresIn < Date.now();

    if (!expired) {
      const session = store.getObject('session');
      if (!session || session === null) {
        axios({
          method: 'get',
          url: process.env.EDUCAZION_SERVER + 'usuarios/current',
          responseType: 'json',
          headers: {
            'Content-Type': 'application/json',
            Authorization: 'Token ' + store.getObject('token'),
          },
        }).then((response) => {
          store.setObjects('session', response.data);
          props.history.push('/main');
        });
      }
    } else {
      store.clear();
      props.history.push('/login');
    }
  }, []);

  return (
    <div>
      <MenuBar match={props.match} history={props.history} />
      <Switch>
        {/* <Route path={`${match.url}/inicio`} component={inicio}/> */}
        <Route path={`${match.url}/estudiantes`} component={estudiantes} />
        <Route path={`${match.url}/`}>
          <Redirect to={`${match.url}/estudiantes`} component={inicio} />
        </Route>
      </Switch>
    </div>
  );
};

export default Main;
