import React from 'react';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction='up' ref={ref} {...props} />;
});

const Confirma = (props) => {
  const {
    open,
    setOpen,
    tituloDialog = '¿Deseas borrar el recurso?',
    actionDialog,
  } = props;

  const handleClose = () => setOpen(false);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}>
      <DialogTitle id='alert-dialog-slide-title'>{tituloDialog}</DialogTitle>
      <DialogActions>
        <Button onClick={actionDialog} color='primary'>
          Sí
        </Button>
        <Button onClick={handleClose} color='primary'>
          No
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Confirma;
