var store = window.localStorage;
var sessionStore = window.sessionStorage;
var dec = window.atob;
var enc = window.btoa;

const getObject = (key) => {
  if (store.getItem(key)) {
    return dec(store.getItem(key));
  } else {
    return null;
  }
};

const clear = () => {
  store.clear();
};

const setObject = (key, data) => {
  store.setItem(key, enc(data));
  return true;
};

const deleteObject = (key) => {
  store.removeItem(key);
};

const getObjects = (key) => {
  if (store.getItem(key)) {
    return JSON.parse(dec(store.getItem(key)));
  } else {
    return null;
  }
};

const setObjects = (key, objects) => {
  store.setItem(key, enc(JSON.stringify(objects)));
  return true;
};

const deleteObjects = (key) => {
  store.removeItem(key);
  return true;
};

const getItem = (name) => {
  if (sessionStore.getItem(name)) {
    return dec(sessionStore.getItem(name));
  } else {
    return null;
  }
};

const setItem = (key, object) => {
  sessionStore.setItem(key, enc(object));
  return true;
};

const deleteItem = (key) => {
  sessionStore.removeItem(key);
  return true;
};

const getItems = (name) => {
  if (sessionStore.getItem(name)) {
    return JSON.parse(dec(sessionStore.getItem(name)));
  } else {
    return null;
  }
};

const setItems = (key, objects) => {
  sessionStore.setItem(key, enc(JSON.stringify(objects)));
  return true;
};

const deleteItems = (key) => {
  sessionStore.removeItem(key);
  return true;
};

export {
  getObject,
  setObject,
  deleteObject,
  getObjects,
  setObjects,
  deleteObjects,
  getItem,
  getItems,
  deleteItem,
  deleteItems,
  setItem,
  setItems,
  clear,
};
