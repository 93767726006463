import React from 'react';

import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Tooltip,
  Button,
} from '@material-ui/core';

import { Add, Close } from '@material-ui/icons';

const getSteps = () => {
  return ['Condiciones', 'Selecciona la fotografía', 'Confirmar'];
};

const Condiciones = () => {
  return (
    <CardContent>
      <Grid
        container
        spacing={3}
        direction='row'
        justifyContent='center'
        alignItems='stretch'>
        <Grid item xs={12}>
          <Typography variant='h6'>Considera que la fotografía</Typography>
        </Grid>
        <Grid item xs={12}>
          <List>
            <ListItem button>
              <ListItemText>Debe estar en formato vertical</ListItemText>
            </ListItem>
            <ListItem button>
              <ListItemText>
                Aparecerá de este modo en documentos oficiales
              </ListItemText>
            </ListItem>
            <ListItem button>
              <ListItemText>Es responsabilidad del alumno o tutor</ListItemText>
            </ListItem>
          </List>
        </Grid>
      </Grid>
    </CardContent>
  );
};

const Elige = ({ fotografia, setFotografia, handleImage }) => {
  return (
    <CardContent>
      <Grid item container spacing={3} alignItems='center'>
        <Grid item xs={12}>
          <input
            type='file'
            id='imginput'
            name='img'
            onChange={handleImage}
            hidden
          />
        </Grid>
        <Grid
          item
          container
          xs={12}
          alignItems='center'
          justifyContent='center'>
          {fotografia ? (
            <Card elevation={0}>
              <CardHeader
                title={
                  <Typography variant='body1'>Imagen seleccionada</Typography>
                }
                action={
                  <Tooltip title='Eliminar imagen'>
                    <IconButton
                      onClick={() => {
                        setFotografia(null);
                        document.getElementById('imginput').value = null;
                      }}>
                      <Close />
                    </IconButton>
                  </Tooltip>
                }
              />
              <CardContent>
                <img
                  src={fotografia.url}
                  style={{ width: 200, height: 200, objectFit: 'cover' }}
                />
              </CardContent>
            </Card>
          ) : (
            <Grid
              item
              xs={12}
              component={IconButton}
              style={{
                width: 200,
                height: 200,
                border: '1px dashed black',
                flexBasis: 'unset',
              }}
              onClick={() => document.getElementById('imginput').click()}>
              <Add />
            </Grid>
          )}
        </Grid>
      </Grid>
    </CardContent>
  );
};

const Acepta = ({ loading }) => {
  return (
    <CardContent>
      <Grid item container spacing={3} alignItems='center'>
        {loading ? (
          <CircularProgress />
        ) : (
          <Grid item xs={12}>
            <Typography variant='subtitle1'>
              Hemos finalizado la subida de la fotografía. Presiona el botón de
              finalizar para salir.
            </Typography>
          </Grid>
        )}
      </Grid>
    </CardContent>
  );
};

const getStepContent = (step, params) => {
  switch (step) {
    case 0:
      return <Condiciones />;
    case 1:
      return (
        <Elige
          fotografia={params.fotografia}
          handleImage={params.handleImage}
          setFotografia={params.setFotografia}
        />
      );
    case 2:
      return <Acepta loading={params.loading} />;
    default:
      return null;
  }
};

const FotografiaDialog = ({
  openDialog,
  finalizar,
  cancelar,
  activeStep,
  fotografia,
  setFotografia,
  setActiveStep,
  uploadImage,
  handleImage,
  loading,
}) => {
  const steps = getSteps();
  return (
    <Dialog open={openDialog} fullWidth={true} maxWidth='md'>
      <DialogTitle>Actualizar la fotografía del estudiante</DialogTitle>
      <DialogContent>
        <Stepper orientation='horizontal' activeStep={activeStep}>
          {steps.map((label, index) => {
            return (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        {getStepContent(activeStep, {
          fotografia,
          handleImage,
          setFotografia,
          loading,
        })}
      </DialogContent>
      <DialogActions>
        {activeStep !== 2 && (
          <>
            <Button color='default' onClick={cancelar}>
              Cancelar
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={() => {
                switch (activeStep) {
                  case 0:
                    setActiveStep((curr) => curr + 1);
                    break;
                  case 1:
                    uploadImage();
                    setActiveStep((curr) => curr + 1);
                    break;
                  case 2:
                    break;
                }
              }}
              disabled={activeStep === 1 && fotografia === null}>
              {['Lo entiendo', 'Cargar fotografía', ''][activeStep]}
            </Button>
          </>
        )}
        {activeStep === 2 && <Button onClick={finalizar}>Finalizar</Button>}
      </DialogActions>
    </Dialog>
  );
};

export default FotografiaDialog;
