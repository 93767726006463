import React from 'react';
import { Box, TextField, Typography } from '@material-ui/core';

export const TutorInfo = ({ tutor, handleTutor, editar, classes }) => {
  return (
    <Box padding='24px 0' display='flex' flexDirection='column' gridGap='8px'>
      <Typography variant='h6'>Datos del tutor</Typography>
      <Box
        display='grid'
        gridTemplateColumns='repeat(auto-fit, minmax(400px, 1fr))'
        gridGap='16px'>
        <TextField
          fullWidth
          disabled={!editar}
          id='tutorName'
          label='Nombre'
          value={tutor?.nombre ?? ''}
          className={classes.textField}
          onChange={(e) => handleTutor(e.target.value, 'nombre')}
        />
        <TextField
          fullWidth
          disabled={!editar}
          id='tutorEmail'
          label='Correo Electrónico'
          value={tutor?.email ?? ''}
          className={classes.textField}
          onChange={(e) => handleTutor(e.target.value, 'email')}
        />
        <TextField
          fullWidth
          disabled={!editar}
          id='tutorPhone'
          label='Teléfono'
          value={tutor?.telefono ?? ''}
          className={classes.textField}
          onChange={(e) => handleTutor(e.target.value, 'telefono')}
        />
      </Box>
    </Box>
  );
};
