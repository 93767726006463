// React
import React from 'react';
import axios from 'axios';
// Components
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Alert from '../Alert';
import AgregarMovimiento from './AgregarMovimiento';
import Divider from '@material-ui/core/Divider';
import CircularProgress from '@material-ui/core/CircularProgress';
import Confirma from '../../utils/Confirma';
import * as store from '../../utils/localStore';

import {
  IconButton,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Container,
  Tooltip,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  TextField,
} from '@material-ui/core';

import { Edit, Delete, AddCircleOutline } from '@material-ui/icons';

// Other Reqs
const { fromJS } = require('immutable');
import { withStyles, makeStyles } from '@material-ui/core/styles';

const getPaymentStatus = (status) => {
  switch (status) {
    case 'paid':
      return <b style={{ color: '#4caf50' }}>Pagado</b>;
    case 'pending_payment':
      return <b style={{ color: '#ffc107' }}>Pendiente</b>;
    case 'declined':
      return <b style={{ color: '#f44336' }}>Declinado</b>;
    case 'expired':
      return <b style={{ color: '#795548' }}>Expirado</b>;
    case 'refunded':
      return <b style={{ color: '#0288d1' }}>Reembolsado</b>;
    default:
      return <b style={{ color: '#757575' }}>Desconocido</b>;
  }
};

const parseDate = (datetext) => {
  const date = new Date(datetext);
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

const styles = (theme) => ({
  root: {
    padding: '30px',
    overflow: 'scroll',
    textAlign: 'center',
    color: 'rgb(30,30,30)',
    height: 'auto',
  },
  margin: {
    margin: theme.spacing(1),
  },
  butt: {
    textAlign: 'center',
  },
  textField: {
    flexBasis: 200,
  },
});

const EstadoCuenta = (props) => {
  const [selectedMove, setSelectedMove] = React.useState([]);
  const [alerta, setAlerta] = React.useState({
    open: false,
    title: '',
    text: '',
  });
  const [historialPagos, setHistorialPagos] = React.useState({});
  const [estados, setEstados] = React.useState(['loading']);
  const [calculos, setCalculos] = React.useState({
    adeudoInicial: 0,
    restante: 0,
    abonoTotal: 0,
    cargoTotal: 0,
  });
  const setEstudiante = props.setEstudiante;

  function closeAlert() {
    setAlerta({ open: false, title: '', text: '' });
  }

  function eliminarMovimiento() {
    var h = historialPagos;
    if (selectedMove.carbs != '') {
      h.abonos.splice(selectedMove.index, 1);
    } else {
      var ni = selectedMove.index - h.abonos.length;
      h.cargos.splice(ni, 1);
    }
    var he = fromJS(h);
    props.setHistorialPagos(he.toJS());
    setAlerta({ open: false, title: alerta.title, text: alerta.text });
  }

  React.useEffect(() => {
    var newEstados = [];
    if (props.estudiante.intentosPago) {
      for (var i = 0; i < props.estudiante.intentosPago.length; i++) {
        newEstados.push('loading');
      }
      setHistorialPagos(props.historialPagos);
      props.estudiante.intentosPago.map((intento, index) => {
        if (intento.orderId) {
          if (intento.orderId.indexOf('ord_') > -1) {
            axios({
              method: 'GET',
              url: process.env.EDUCAZION_SERVER + 'conekta/' + intento.orderId,
              responseType: 'json',
              headers: {
                'Content-Type': 'application/json',
              },
            }).then((response) => {
              newEstados[index] = getPaymentStatus(
                response.data._json?.payment_status
              );
              setEstados(newEstados);
            });
          } else {
            newEstados[index] = getPaymentStatus('N/A');
            setEstados(newEstados);
          }
        } else {
          newEstados[index] = getPaymentStatus('N/A');
          setEstados(newEstados);
        }
      });
    } else {
      setEstados([]);
    }
  }, [props.historialPagos]);

  const { classes, estudiante } = props;

  if (props.estudiante.intentosPago)
    return (
      <div className={classes.root}>
        <Container>
          <Grid container spacing={3} alignItems='stretch' direction='column'>
            <Grid item xs={12}>
              <IntentosPagoTable
                estudiante={estudiante}
                estados={estados}
                setEstudiante={setEstudiante}
              />
            </Grid>
            <Grid item xs={12}>
              <AbonosTable
                estudiante={estudiante}
                setEstudiante={setEstudiante}
              />
            </Grid>
            <Grid item xs={12}>
              <CargosTable
                estudiante={estudiante}
                setEstudiante={setEstudiante}
              />
            </Grid>
          </Grid>
        </Container>

        <Alert
          title={alerta.title}
          open={alerta.open}
          text={alerta.text}
          closeAlert={closeAlert}
          success={eliminarMovimiento}
        />
      </div>
    );
  else return null;
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  title: {
    flex: '1 1 100%',
  },
}));

const IntentosPagoTable = ({ estudiante, estados, setEstudiante }) => {
  const classes = useToolbarStyles();
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openNuevo, setOpenNuevo] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(null);

  const handleClose = () => setOpen(false);
  const handleCloseNuevo = () => setOpenNuevo(false);

  const Editar = ({ open, onClose }) => {
    const [data, setData] = React.useState({
      id: dataToEdit?._id,
      matricula: estudiante.usuario,
      orderId: dataToEdit?.orderId,
      concepto: dataToEdit?.concepto,
      cantidad: dataToEdit?.cantidad,
      moneda: dataToEdit?.moneda,
      estatus: 'Rechazado',
    });

    const update = (type) => (event) => {
      const newData = { ...data };
      newData[type] = event.target.value;
      setData(newData);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const dataToSend = {
        id: data.id,
        matricula: data.matricula,
        orderId: data.orderId,
        concepto: data.concepto,
        cantidad: data.cantidad,
        moneda: data.moneda,
        fecha: new Date(),
      };
      axios
        .post(
          `${process.env.EDUCAZION_SERVER}estudiantes/intentosPago`,
          dataToSend,
          {
            headers: {
              Authorization: `Token ${store.getObject('token')}`,
            },
          }
        )
        .then((response) => {
          const newEst = response.data.estudiante;
          setEstudiante(newEst);
        })
        .catch((error) => {});

      onClose();
    };

    return (
      <Dialog maxWidth='md' open={open} onClose={onClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edición De Intento De Pago</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Editar el contenido de un intento de pago
            </DialogContentText>
            <Grid container spacing={3} alignItems='stretch'>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Concepto'
                  value={data.concepto}
                  onChange={update('concepto')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Cantidad'
                  value={data.cantidad}
                  type='numeric'
                  onChange={update('cantidad')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Moneda'
                  value={data.moneda}
                  onChange={update('moneda')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='OrderId'
                  value={data.orderId}
                  onChange={update('orderId')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='secondary' onClick={onClose}>
              Cancelar
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  const Nuevo = ({ open, onClose }) => {
    const [data, setData] = React.useState({
      matricula: estudiante.usuario,
      orderId: '',
      concepto: '',
      cantidad: 0,
      moneda: 'MXN',
      estatus: 'Rechazado',
    });

    const update = (type) => (event) => {
      const newData = { ...data };
      newData[type] = event.target.value;
      setData(newData);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const dataToSend = {
        matricula: data.matricula,
        orderId: data.orderId,
        concepto: data.concepto,
        cantidad: data.cantidad,
        moneda: data.moneda,
        estatus: data.estatus,
      };
      axios
        .post(`${process.env.EDUCAZION_SERVER}estudiantes/pago`, dataToSend)
        .then((response) => {
          const newEst = response.data.estudiante;
          setEstudiante(newEst);
        })
        .catch((error) => {});
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Nuevo Intento de Pago</DialogTitle>
          <DialogContent>
            <DialogContentText>Crear nuevo intento de pago</DialogContentText>
            <Grid container spacing={3} alignItems='stretch'>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='orderId'
                  value={data.orderId}
                  onChange={update('orderId')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Concepto'
                  value={data.concepto}
                  onChange={update('concepto')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Cantidad'
                  value={data.cantidad}
                  onChange={update('cantidad')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Moneda'
                  value={data.moneda}
                  onChange={update('moneda')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='secondary' onClick={onClose}>
              Cancelar
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  return (
    <>
      <Editar open={open} onClose={handleClose} />
      <Nuevo open={openNuevo} onClose={handleCloseNuevo} />
      <Confirma
        open={openConfirm}
        setOpen={setOpenConfirm}
        tituloDialog='¿Deseas Borrar el Intento de Pago?'
        actionDialog={() => {
          axios
            .delete(`${process.env.EDUCAZION_SERVER}estudiantes/intentosPago`, {
              headers: { Authorization: 'Token ' + store.getObject('token') },
              params: { matricula: estudiante.usuario, id: dataToEdit._id },
            })
            .then((response) => {
              const newEst = response.data.estudiante;
              setEstudiante(newEst);
              setOpenConfirm(false);
            });
        }}
      />
      <TableContainer component={Paper}>
        <Toolbar className={classes.root}>
          <Typography className={classes.title} variant='h6' component='div'>
            Intentos de Pago
          </Typography>
          <Tooltip title='Añadir intento de pago' placement='top'>
            <IconButton onClick={() => setOpenNuevo(true)}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Concepto</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Moneda</TableCell>
              <TableCell>Estatus</TableCell>
              <TableCell>Editar</TableCell>
              <TableCell>Eliminar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estudiante.intentosPago?.map((intento, index) => (
              <TableRow key={index}>
                <TableCell>{parseDate(intento.fecha)}</TableCell>
                <TableCell>{intento.concepto}</TableCell>
                <TableCell>{intento.cantidad}</TableCell>
                <TableCell>{intento.moneda}</TableCell>
                <TableCell>
                  {estados[index] == 'loading' ? (
                    <CircularProgress color='secondary' />
                  ) : (
                    estados[index]
                  )}
                </TableCell>
                <TableCell>
                  <Tooltip title='Editar' placement='top'>
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setDataToEdit(intento);
                      }}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title='Eliminar' placement='top'>
                    <IconButton
                      onClick={() => {
                        setOpenConfirm(true);
                        setDataToEdit(intento);
                      }}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const AbonosTable = ({ estudiante, setEstudiante }) => {
  const classes = useToolbarStyles();

  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openNuevo, setOpenNuevo] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(null);

  const handleClose = () => setOpen(false);
  const handleCloseNuevo = () => setOpenNuevo(false);

  const Editar = ({ open, onClose }) => {
    const [data, setData] = React.useState({
      id: dataToEdit?._id,
      matricula: estudiante.usuario,
      concepto: dataToEdit?.concepto,
      cantidad: dataToEdit?.cantidad,
      moneda: dataToEdit?.moneda,
      fecha: new Date(),
      tipo: 'abono',
    });

    const update = (type) => (event) => {
      const newData = { ...data };
      newData[type] = event.target.value;
      setData(newData);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const dataToSend = {
        id: data.id,
        matricula: data.matricula,
        concepto: data.concepto,
        cantidad: data.cantidad,
        moneda: data.moneda,
        fecha: new Date(),
        tipo: data.tipo,
      };
      axios
        .post(
          `${process.env.EDUCAZION_SERVER}estudiantes/historialPago`,
          dataToSend,
          {
            headers: {
              Authorization: `Token ${store.getObject('token')}`,
            },
          }
        )
        .then((response) => {
          const newEst = response.data.estudiante;
          setEstudiante(newEst);
        })
        .catch((error) => {});

      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose} maxWidth='md'>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Editar abonos</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Editar el contenido de un Abono
            </DialogContentText>
            <Grid container spacing={3} alignItems='stretch'>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Concepto'
                  value={data.concepto}
                  onChange={update('concepto')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Cantidad'
                  value={data.cantidad}
                  onChange={update('cantidad')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Moneda'
                  value={data.moneda}
                  onChange={update('moneda')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='secondary' onClick={onClose}>
              Cancelar
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  const Nuevo = ({ open, onClose }) => {
    const [data, setData] = React.useState({
      matricula: estudiante.usuario,
      orderId: '',
      concepto: '',
      cantidad: 0,
      moneda: 'MXN',
      estatus: 'Pagado',
    });

    const update = (type) => (event) => {
      const newData = { ...data };
      newData[type] = event.target.value;
      setData(newData);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const dataToSend = {
        matricula: data.matricula,
        orderId: data.orderId,
        concepto: data.concepto,
        cantidad: data.cantidad,
        moneda: data.moneda,
        estatus: data.estatus,
      };
      axios
        .post(`${process.env.EDUCAZION_SERVER}estudiantes/pago`, dataToSend)
        .then((response) => {
          const newEst = response.data.estudiante;
          setEstudiante(newEst);
        })
        .catch((error) => {});
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Nuevo Abono</DialogTitle>
          <DialogContent>
            <DialogContentText>Crear nuevo abono</DialogContentText>
            <Grid container spacing={3} alignItems='stretch'>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='OrderId'
                  value={data.orderId}
                  onChange={update('orderId')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Concepto'
                  value={data.concepto}
                  onChange={update('concepto')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Cantidad'
                  value={data.cantidad}
                  onChange={update('cantidad')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Moneda'
                  value={data.moneda}
                  onChange={update('moneda')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='secondary' onClick={onClose}>
              Cancelar
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  return (
    <>
      <Editar open={open} onClose={handleClose} />
      <Nuevo open={openNuevo} onClose={handleCloseNuevo} />
      <Confirma
        open={openConfirm}
        setOpen={setOpenConfirm}
        tituloDialog='¿Deseas Borrar el Abono?'
        actionDialog={() => {
          axios
            .delete(
              `${process.env.EDUCAZION_SERVER}estudiantes/historialPago/`,
              {
                headers: { Authorization: 'Token ' + store.getObject('token') },
                params: {
                  matricula: estudiante.usuario,
                  id: dataToEdit._id,
                  tipo: 'abono',
                },
              }
            )
            .then((response) => {
              const newEst = response.data.estudiante;
              setEstudiante(newEst);
              setOpenConfirm(false);
            });
        }}
      />
      <TableContainer component={Paper}>
        <Toolbar className={classes.root}>
          <Typography variant='h6' className={classes.title} component='div'>
            Abonos
          </Typography>
          <Tooltip title='Añadir abono' placement='top'>
            <IconButton onClick={() => setOpenNuevo(true)}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Concepto</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Moneda</TableCell>
              <TableCell>Editar</TableCell>
              <TableCell>Eliminar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estudiante.historialPagos?.abonos?.map((abono, index) => (
              <TableRow key={index}>
                <TableCell>{parseDate(abono.fecha)}</TableCell>
                <TableCell>{abono.concepto}</TableCell>
                <TableCell>{abono.cantidad}</TableCell>
                <TableCell>{abono.moneda}</TableCell>
                <TableCell>
                  <Tooltip title='Editar' placement='top'>
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setDataToEdit(abono);
                      }}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title='Delete' placement='top'>
                    <IconButton
                      onClick={() => {
                        setOpenConfirm(true);
                        setDataToEdit(abono);
                      }}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

const CargosTable = ({ estudiante, setEstudiante }) => {
  const classes = useToolbarStyles();
  const [open, setOpen] = React.useState(false);
  const [openConfirm, setOpenConfirm] = React.useState(false);
  const [openNuevo, setOpenNuevo] = React.useState(false);
  const [dataToEdit, setDataToEdit] = React.useState(null);

  const handleClose = () => setOpen(false);
  const handleCloseNuevo = () => setOpenNuevo(false);

  const Editar = ({ open, onClose }) => {
    const [data, setData] = React.useState({
      id: dataToEdit?._id,
      matricula: estudiante.usuario,
      concepto: dataToEdit?.concepto,
      cantidad: dataToEdit?.cantidad,
      moneda: dataToEdit?.moneda,
      fecha: new Date(),
      tipo: 'cargo',
    });

    const update = (type) => (event) => {
      const newData = { ...data };
      newData[type] = event.target.value;
      setData(newData);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const dataToSend = {
        id: data.id,
        matricula: data.matricula,
        concepto: data.concepto,
        cantidad: data.cantidad,
        moneda: data.moneda,
        fecha: new Date(),
        tipo: data.tipo,
      };
      axios
        .post(
          `${process.env.EDUCAZION_SERVER}estudiantes/historialPago`,
          dataToSend,
          {
            headers: {
              Authorization: `Token ${store.getObject('token')}`,
            },
          }
        )
        .then((response) => {
          const newEst = response.data.estudiante;
          setEstudiante(newEst);
        })
        .catch((error) => {});

      onClose();
    };
    return (
      <Dialog maxWidth='md' open={open} onClose={onClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Edición de pago</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Editar el contenido de un Intento de Pago
            </DialogContentText>
            <Grid container spacing={3} alignItems='stretch'>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Concepto'
                  value={data.concepto}
                  onChange={update('concepto')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Cantidad'
                  value={data.cantidad}
                  onChange={update('cantidad')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Moneda'
                  value={data.moneda}
                  onChange={update('moneda')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='secondary' onClick={onClose}>
              Cancelar
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              Guardar
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  const Nuevo = ({ open, onClose }) => {
    const [data, setData] = React.useState({
      matricula: estudiante.usuario,
      concepto: '',
      cantidad: 0,
      moneda: 'MXN',
      estatus: 'Por pagar',
    });

    const update = (type) => (event) => {
      const newData = { ...data };
      newData[type] = event.target.value;
      setData(newData);
    };

    const handleSubmit = (event) => {
      event.preventDefault();
      const dataToSend = {
        matricula: data.matricula,
        concepto: data.concepto,
        cantidad: data.cantidad,
        moneda: data.moneda,
        estatus: data.estatus,
        orderId: 'useless',
      };
      axios
        .post(`${process.env.EDUCAZION_SERVER}estudiantes/pago`, dataToSend)
        .then((response) => {
          const newEst = response.data.estudiante;
          setEstudiante(newEst);
        })
        .catch((error) => {});
      onClose();
    };

    return (
      <Dialog open={open} onClose={onClose}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Nuevo Intento de Pago</DialogTitle>
          <DialogContent>
            <DialogContentText>Crear nuevo intento de pago</DialogContentText>
            <Grid container spacing={3} alignItems='stretch'>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Concepto'
                  value={data.concepto}
                  onChange={update('concepto')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Cantidad'
                  value={data.cantidad}
                  onChange={update('cantidad')}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant='outlined'
                  label='Moneda'
                  value={data.moneda}
                  onChange={update('moneda')}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button variant='contained' color='secondary' onClick={onClose}>
              Cancelar
            </Button>
            <Button variant='contained' color='primary' type='submit'>
              Crear
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    );
  };

  return (
    <>
      <Editar open={open} onClose={handleClose} />
      <Nuevo open={openNuevo} onClose={handleCloseNuevo} />
      <Confirma
        open={openConfirm}
        setOpen={setOpenConfirm}
        tituloDialog='¿Deseas Borrar el Cargo?'
        actionDialog={() => {
          axios
            .delete(
              `${process.env.EDUCAZION_SERVER}estudiantes/historialPago`,
              {
                headers: { Authorization: 'Token ' + store.getObject('token') },
                params: {
                  matricula: estudiante.usuario,
                  tipo: 'cargo',
                  id: dataToEdit._id,
                },
              }
            )
            .then((response) => {
              const newEst = response.data.estudiante;
              setEstudiante(newEst);
              setOpenConfirm(false);
            });
        }}
      />
      <TableContainer component={Paper}>
        <Toolbar className={classes.root}>
          <Typography variant='h6' className={classes.title} component='div'>
            Cargos
          </Typography>
          <Tooltip title='Añadir cargo' placement='top'>
            <IconButton onClick={() => setOpenNuevo(true)}>
              <AddCircleOutline />
            </IconButton>
          </Tooltip>
        </Toolbar>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Fecha</TableCell>
              <TableCell>Concepto</TableCell>
              <TableCell>Cantidad</TableCell>
              <TableCell>Moneda</TableCell>
              <TableCell>Editar</TableCell>
              <TableCell>Eliminar</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {estudiante.historialPagos?.cargos?.map((cargo, index) => (
              <TableRow key={index}>
                <TableCell>{parseDate(cargo.fecha)}</TableCell>
                <TableCell>{cargo.concepto}</TableCell>
                <TableCell>{cargo.cantidad}</TableCell>
                <TableCell>{cargo.moneda}</TableCell>
                <TableCell>
                  <Tooltip title='Editar' placement='top'>
                    <IconButton
                      onClick={() => {
                        setOpen(true);
                        setDataToEdit(cargo);
                      }}>
                      <Edit />
                    </IconButton>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  <Tooltip title='Delete' placement='top'>
                    <IconButton
                      onClick={() => {
                        setOpenConfirm(true);
                        setDataToEdit(cargo);
                      }}>
                      <Delete />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default withStyles(styles, { withTheme: true })(EstadoCuenta);
