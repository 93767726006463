import {
  Box,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React from 'react';
import MenuImpresiones from '../MenuImpresiones';
import { useControlActions } from '../useControlActions';
import ArchiveIcon from '@material-ui/icons/Archive';
import MailIcon from '@material-ui/icons/Email';
import Lock from '@material-ui/icons/Lock';
import LockOpen from '@material-ui/icons/LockOpen';
import { CloudUpload } from '@material-ui/icons';

export const ActionsBar = ({
  editedStudent,
  uploading,
  estudiantes,
  classes,
  setEditar,
  editar,
  rol,
  setLoading,
  setEstudianteIndex,
  loading,
  setSnackbar,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl1, setAnchorEl1] = React.useState(null);

  const {
    getCSV,
    getCSVCurrent,
    getCSVMoodle,
    getCSVMoodleCurrent,
    uploadCSV,
    sendBienvenida,
    sendValidacion,
    uploadActa,
  } = useControlActions({
    estudiantes,
    editedStudent,
    setLoading,
    setEstudianteIndex,
    setSnackbar,
  });

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMenuClick1 = (event) => {
    setAnchorEl1(event.currentTarget);
  };

  const handleMenuClose1 = () => {
    setAnchorEl1(null);
  };
  return (
    <Box display='flex' alignItems='center'>
      <div
        style={{
          width: '100%',
          textAlign: 'center',
        }}>
        <Typography variant='h3'>{uploading}</Typography>
      </div>
      <input type='file' id='csvupload' onChange={uploadCSV} hidden />
      <input type='file' id='acta' onChange={uploadActa} hidden />
      {rol == 'administrador' && (
        <>
          {editar ? (
            <Tooltip title='Proteger'>
              <IconButton
                aria-label='delete'
                className={classes.margin}
                size='medium'
                onClick={() => setEditar(false)}>
                <LockOpen fontSize='inherit' />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title='Editar'>
              <IconButton
                aria-label='delete'
                className={classes.margin}
                size='medium'
                onClick={() => setEditar(true)}>
                <Lock fontSize='inherit' />
              </IconButton>
            </Tooltip>
          )}
          <MenuImpresiones
            estudiante={editedStudent}
            loading={loading}
            setLoading={setLoading}
          />
          <Menu
            id='simple-menu'
            anchorEl={anchorEl1}
            keepMounted
            open={Boolean(anchorEl1)}
            onClose={handleMenuClose1}>
            <MenuItem onClick={sendBienvenida}>
              Enviar Notificación de Plataforma Abierta
            </MenuItem>
            <MenuItem onClick={sendValidacion}>
              Enviar Correo de Validación
            </MenuItem>
          </Menu>

          <Tooltip title='Enviar Correo'>
            <IconButton
              aria-label='delete'
              className={classes.margin}
              size='medium'
              onClick={handleMenuClick1}>
              <MailIcon />
            </IconButton>
          </Tooltip>
          <Menu
            id='simple-menu'
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}>
            <MenuItem onClick={getCSVCurrent}>
              CSV Regular ({editedStudent.nombres})
            </MenuItem>
            <MenuItem onClick={getCSVMoodleCurrent}>
              CSV para Moodle ({editedStudent.nombres})
            </MenuItem>
            <Divider />
            <MenuItem onClick={getCSV}>CSV Regular</MenuItem>
            <MenuItem onClick={getCSVMoodle}>CSV para Moodle</MenuItem>
          </Menu>
          <Tooltip title='Descargar CSV'>
            <IconButton
              aria-label='delete'
              className={classes.margin}
              size='medium'
              onClick={handleMenuClick}>
              <ArchiveIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title='Subir acta de nacimiento'>
            <IconButton
              aria-label='delete'
              className={classes.margin}
              size='medium'
              onClick={() => document.getElementById('acta').click()}>
              <CloudUpload />
            </IconButton>
          </Tooltip>
        </>
      )}
    </Box>
  );
};
