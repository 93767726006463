import React from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import {
  HashRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';

// import App from './App';
import * as serviceWorker from './serviceWorker';

//Views
import login from './vistas/login/login';
import main from './vistas/main/main';

import './index.css';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#26a69a',
    },
    secondary: {
      main: '#eeeeee',
    },
  },
});

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Router>
        <Switch>
          <Route path='/login' component={login} />
          <Route path='/main' component={main} />
          <Route path='/' exact>
            <Redirect to='/login' component={login} />
          </Route>
        </Switch>
      </Router>
    </MuiPickersUtilsProvider>
  </MuiThemeProvider>,
  document.getElementById('app')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
